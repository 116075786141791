import React from "react";
import classnames from "classnames";
import { SecondaryNavigationRouterItemProps } from "./RouterItem.types";
import { Link, useLocation, useResolvedPath } from "react-router-dom";
import { useAnimatedNavigate } from "../../../Hooks/useAnimatedNavigate";

export const RouterItem: React.FC<SecondaryNavigationRouterItemProps> =
  React.forwardRef<HTMLLIElement, SecondaryNavigationRouterItemProps>(
    (
      {
        animate = true,
        end,
        children,
        className: classNameProp = "",
        link,
        ...props
      },
      ref
    ) => {
      const location = useLocation();
      const navigate = useAnimatedNavigate();
      let locationPathname = location.pathname;
      let path = useResolvedPath(link, { relative: props.relative });
      let isActive =
        locationPathname === path.pathname ||
        (!end &&
          locationPathname.startsWith(path.pathname) &&
          locationPathname.charAt(path.pathname.length) === "/");

      let className: string | undefined;
      if (typeof classNameProp === "function") {
        className = classNameProp({ isActive });
      } else {
        className = classNameProp;
      }
      const itemClasses = classnames(
        "secondary-nav__item",
        {
          "secondary-nav__item--active": isActive,
        },
        className
      );

      return (
        <li className={itemClasses} {...props} ref={ref}>
          {animate ? (
            <a
              className="secondary-nav__link"
              onClick={() => {
                navigate(link);
              }}
            >
              <div className="secondary-nav__linktext">{children}</div>
            </a>
          ) : (
            <Link className="secondary-nav__link" to={link}>
              <div className="secondary-nav__linktext">{children}</div>
            </Link>
          )}
        </li>
      );
    }
  );

export default RouterItem;
