import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { LabelProps, LabelTextProps } from "./Label.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Label: FunctionComponent<LabelProps> = React.forwardRef<
  HTMLLabelElement,
  LabelProps
>(({ error, children, className, inline, ...props }, ref) => {
  const labelClasses = classnames(
    "label",
    {
      "label--inline": inline,
    },
    className
  );
  return (
    <label className={labelClasses} {...props} ref={ref}>
      {children}
      {error ? (
        <Icon
          icon={IconKeys.AlertTriangle}
          className="text-o600 icon--smaller"
        />
      ) : null}
    </label>
  );
});

export default Label;

export const LabelText: FunctionComponent<LabelTextProps> = ({
  children,
  ...props
}) => {
  return (
    <div className="label__text" {...props}>
      {children}
    </div>
  );
};
