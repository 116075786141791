import React from "react";
import classnames from "classnames";
import { Icons, IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { kebabToJS } from "@gbg/gbgcomponentlibrary/src/_utilities";
import Icon from "../../Icon/Icon";
import { SlugProps } from "./Slug.types";

export const Slug = React.forwardRef<HTMLInputElement, SlugProps>(
  (
    {
      className,
      slug,
      icon,
      type,
      error,
      disabled,
      placeholder,
      ...props
    }: SlugProps,
    ref
  ) => {
    type = type ?? "text";
    const checkboxClasses = classnames(
      "control",
      "control--slug",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <div className={checkboxClasses} aria-label="textfield">
        <div className="control__slug">
          {Object.keys(Icons).find((k) => k === kebabToJS(slug)) ? (
            <Icon icon={slug} className={"text-n700"}></Icon>
          ) : (
            slug
          )}
        </div>
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
          ref={ref}
        ></input>
        {icon ? (
          typeof icon === "string" ? (
            <Icon icon={icon}></Icon>
          ) : icon?.icon ? (
            <Icon icon={icon.icon} className={icon.className}></Icon>
          ) : null
        ) : null}
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Slug;
