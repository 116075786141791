import React, { useState } from "react";
import classnames from "classnames";
import { SecondaryNavigationSectionProps } from "./Section.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Section: React.FC<SecondaryNavigationSectionProps> = React.forwardRef<
  HTMLUListElement,
  SecondaryNavigationSectionProps
>(({ isOpen, title, collapsable, children, className, ...props }, ref) => {
  const [open, setOpen] = useState(isOpen ?? false);
  const sectionClasses = classnames(
    "primary-nav__section",
    {
      "primary-nav__section--collapsable": collapsable,
      "primary-nav__section--open": open,
    },
    className
  );
  return (
    <ul
      className={sectionClasses}
      onClick={() => {
        setOpen(!open);
      }}
      {...props}
      ref={ref}
    >
      {title ? (
        <li className="secondary-nav__item secondary-nav__item--title">
          {title}
          {collapsable ? (
            <Icon
              icon={IconKeys.ChevronDown}
              className={"icon--smaller"}
            ></Icon>
          ) : null}
        </li>
      ) : null}
      {children}
    </ul>
  );
});

export default Section;
