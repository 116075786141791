import React, { useEffect } from "react";
import { createContext, useMemo, useRef, useState } from "react";
import { AccordionItemState, AccordionProps } from "./Accordion.types";

interface AccordionStore {
  accordionRef: React.MutableRefObject<null> | null;
  applyTransition?: boolean;
  alwaysOpen?: boolean;
  accordionItemState: AccordionItemState;
  setState: React.Dispatch<React.SetStateAction<AccordionItemState>>;
}

export const AccordionContext = createContext<AccordionStore>({
  accordionRef: null,
  applyTransition: true,
  alwaysOpen: false,
  accordionItemState: {},
  setState: () => {},
});

export const Accordion: React.FC<AccordionProps> = ({
  children,
  className = "",
  alwaysOpen,
  applyTransition = true,
  ...props
}) => {
  const accordionRef = useRef(null);
  const [accordionItemState, setState] = useState<AccordionItemState>({});
  const value = useMemo(() => {
    return {
      accordionRef,
      applyTransition,
      alwaysOpen,
      accordionItemState,
      setState,
    };
  }, [alwaysOpen, accordionItemState, applyTransition]);

  return (
    <AccordionContext.Provider value={value}>
      <div className="accordion__wrapper" ref={accordionRef} {...props}>
        <div className={className}>{children}</div>
      </div>
    </AccordionContext.Provider>
  );
};

export default Accordion;
