import { NavigateOptions, To, useNavigate } from "react-router-dom";

export const useAnimatedNavigate = (): ((
  to: To,
  options?: NavigateOptions
) => void) => {
  const navigate = useNavigate();
  const anyDoc = document as any;
  if (anyDoc.startViewTransition) {
    return (to: To, options?: NavigateOptions) => {
      anyDoc.startViewTransition(() => {
        navigate(to, options);
      });
    };
  } else {
    return navigate;
  }
};
