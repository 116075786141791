import React, { useState, KeyboardEvent } from "react";
import classnames from "classnames";
import { PrimaryNavigationSectionProps } from "./Section.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Section: React.FC<PrimaryNavigationSectionProps> = React.forwardRef<
  HTMLUListElement,
  PrimaryNavigationSectionProps
>(({ isOpen, title, collapsable, children, className, ...props }, ref) => {
  const [open, setOpen] = useState(isOpen ?? false);
  const sectionClasses = classnames(
    "primary-nav__section",
    {
      "primary-nav__section--collapsable": collapsable,
      "primary-nav__section--open": open,
    },
    className
  );
  return (
    <ul className={sectionClasses} {...props} ref={ref}>
      {title ? (
        <li
          tabIndex={collapsable ? 0 : -1}
          className="primary-nav__item primary-nav__item--title"
          onClick={() => {
            setOpen(!open);
          }}
          onKeyUp={(evt: KeyboardEvent) => {
            if (evt.key === "Enter") {
              setOpen(!open);
            }
          }}
        >
          {title}
          {collapsable ? (
            <Icon icon={IconKeys.ChevronDown} className={"icon--smallr"}></Icon>
          ) : null}
        </li>
      ) : null}
      {children}
    </ul>
  );
});

export default Section;
