import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";
import { FloatMenuProps } from "../FloatMenu/FloatMenu.types";

export interface TopBarProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  brand?: TopBarBrand;
  lightLogo?: boolean;
  user?: User | null;
  menu?: FloatMenuProps | null;
  includeNavLink?: boolean;
  onNavigationToggle?: () => void;
}

export interface User {
  username: string;
  company: string;
}

export enum TopBarBrand {
  GBG = "GBG",
  Loqate = "Loqate",
}
