import React, { useState } from "react";
import classnames from "classnames";
import Icon from "../../Icon/Icon";
import { ColourProps } from "./Colour.types";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Colour = React.forwardRef<HTMLInputElement, ColourProps>(
  (
    {
      className,
      slug,
      type,
      error,
      disabled,
      placeholder,
      value,
      ...props
    }: ColourProps,
    ref
  ) => {
    type = type ?? "text";
    slug = slug ?? "HEX";
    value = value ?? "transparent";

    const [color, setColor] = useState<string>(value);
    const [swatchActive, setSwatchActive] = useState(false);

    const colorHandler = (c: string) => {
      if (/^#?[0-9A-F]{6}$/i.test(c)) {
        if (c.indexOf("#") == -1) {
          c = "#" + c;
        }
        setColor(c);
        setSwatchActive(true);
      } else {
        setColor("transparent");
        setSwatchActive(false);
      }
    };

    const checkboxClasses = classnames(
      "control",
      "control--colour",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );

    return (
      <div className={checkboxClasses} aria-label="textfield">
        <div className="control__slug">{slug}</div>
        <input
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onKeyUp={(evt) => {
            colorHandler(evt.currentTarget.value);
          }}
          {...props}
          ref={ref}
        ></input>
        <div
          className={classnames("control__swatch", {
            "control__swatch--inactive": swatchActive,
          })}
          style={{
            backgroundColor: color,
          }}
        ></div>
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Colour;
