import React, { useEffect } from "react";
import { ModalBodyProps, ModalHeaderProps, ModalProps } from "./Modal.types";
import classnames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Modal.scss";

export const Modal: React.FC<ModalProps> = React.forwardRef<
  HTMLDivElement,
  ModalProps
>(
  (
    {
      fadeIn,
      isVisible,
      className,
      sideImage,
      children,
      onBackgroundClick,
      blurTarget,
      ...props
    },
    ref
  ) => {
    const modalClasses = classnames("modal", {}, className);
    const modalContentClasses = classnames("modal__content", {
      "modal__content--with-image": sideImage,
    });
    useEffect(() => {
      if (isVisible) {
        document.body.classList.add("noscroll");
      } else {
        document.body.classList.remove("noscroll");
      }
    }, [isVisible]);

    return (
      <CSSTransition
        in={isVisible}
        classNames="modal"
        timeout={{ enter: 250, exit: 250 }}
      >
        <div className={modalClasses} {...props} ref={ref}>
          <div
            className="modal__overlay"
            onClick={(e) => {
              if (e.target == e.currentTarget) {
                onBackgroundClick?.();
              }
            }}
          >
            <div className={modalContentClasses}>
              {children}
              {sideImage ? (
                <div
                  className="modal__image"
                  style={{ backgroundImage: `url(${sideImage})` }}
                ></div>
              ) : null}
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
);

export const ModalBody: React.FC<ModalBodyProps> = React.forwardRef<
  HTMLDivElement,
  ModalBodyProps
>(({ className, children, ...props }, ref) => {
  const modalBodyClasses = classnames("modal__body", {}, className);
  return (
    <div className={modalBodyClasses} {...props} ref={ref}>
      {children}
    </div>
  );
});

export const ModalHeader: React.FC<ModalHeaderProps> = React.forwardRef<
  HTMLDivElement,
  ModalHeaderProps
>(({ className, children, ...props }, ref) => {
  const modalHeaderClasses = classnames("modal__header", {}, className);
  return (
    <div className={modalHeaderClasses} {...props} ref={ref}>
      {children}
    </div>
  );
});

export default Modal;
