import { attachResources } from "@gbg/gbgcomponentlibrary/src/_utilities";
import { useEffect, useState } from "react";

export const useThirdPartyScript = (sources: string[]) => {
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState({});

  useEffect(() => {
    try {
      attachResources(sources, () => {
        setIsLoading(false);
      });
    } catch (e) {
      setError(e);
    }
  }, []);

  return {
    loading,
    error,
  };
};
