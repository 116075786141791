import React from "react"
import { FormProps } from "./Form.types"
import classnames from "classnames";

export const Form: React.FC<FormProps> = React.forwardRef<HTMLFormElement, FormProps>(({ title, intro, children, ...props }, ref) => {
    const formClasses = classnames("form");
    return <form
            className={formClasses}
            {...props}
            ref={ref}
            >
                <div className="form__heading">
                    {
                        title ? 
                        <p className="form__title">{title}</p>
                        : null
                    }
                    {
                        intro ?
                        <div className="form__intro">{intro}</div>
                        : null
                    }
                    {children}
                </div>
            </form>
})

export default Form;