import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { TableProps, TableSelectionType } from "./Table.types";
import TableRow from "../TableRow/TableRow";
import TableHead from "../TableHead/TableHead";
import TableHeaderCell from "../TableHeaderCell/TableHeaderCell";
import TableCell from "../TableCell/TableCell";
import TableBody from "../TableBody/TableBody";
import { OverflowMenu } from "../../../Molecules/OverflowMenu/OverflowMenu";
import { TableSortDirection } from "../Table/Table.types";
import Checkbox from "../../Controls/Checkbox/Checkbox";
import { FloatMenuDirection } from "../../Navigation/FloatMenu/FloatMenu.types";
import Spinner from "../../Spinner/Spinner";
import Card, { CardBody } from "../../Cards/Card";
import "./Table.scss";
import Radio from "../../Controls/Radio/Radio";

export const Table: React.FC<TableProps> = React.forwardRef<
  HTMLTableElement,
  TableProps
>(({ children, dataSet, hasExpandableRows, className, ...props }, ref) => {
  const tableClasses = classnames(
    `table`,
    {
      "table-expandable": hasExpandableRows,
    },
    className
  );
  let headers = [];
  if (dataSet) {
    const keys: string[] = [].concat.apply(
      [],
      dataSet.data.map((di) => Object.keys(di))
    );
    let dedupedKeys = keys.filter((item, pos) => {
      return keys.indexOf(item) == pos;
    });
    if (dataSet.cellFilter) {
      if (Array.isArray(dataSet.cellFilter)) {
        dedupedKeys = dataSet.cellFilter;
      } else {
        dedupedKeys = dedupedKeys.filter(dataSet.cellFilter);
      }
    }
    headers = dedupedKeys;
  }

  const [sortedData, setSortedData] = useState<any[]>(
    dataSet ? dataSet.data : null
  );
  const [sortHeader, setSortHeader] = useState<string>(null);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(
    TableSortDirection.None
  );
  const [selectedItems, setSelectedItems] = useState<any[]>(
    dataSet?.data?.filter((di, i) => {
      if (
        !dataSet.initialSelection ||
        (Array.isArray(dataSet.initialSelection) &&
          dataSet.initialSelection.length == 0)
      )
        return false;
      if (!Array.isArray(dataSet.initialSelection)) {
        return dataSet.initialSelection(di, i);
      }
      if (typeof dataSet.initialSelection[0] === "number") {
        return dataSet.initialSelection.indexOf(i) > -1;
      } else {
        return dataSet.initialSelection.indexOf(di) > -1;
      }
    }) ?? []
  ); //TODO type this whole thing???

  useEffect(() => {
    if (dataSet) {
      const sd = [...dataSet.data].sort((a, b) => {
        if (a[sortHeader] == b[sortHeader]) return 0;
        if (a[sortHeader] < b[sortHeader])
          return sortDirection == TableSortDirection.Ascending ? -1 : 1;
        return sortDirection == TableSortDirection.Ascending ? 1 : -1;
      });
      setSortedData(sd);
    }
  }, [sortHeader, sortDirection, dataSet]);

  const cellHeaderHandler = (key: string) => {
    if (!cellIsSortable(key)) return;
    setSortHeader(key);
    setSortDirection(
      sortDirection == TableSortDirection.None ||
        sortDirection == TableSortDirection.Descending
        ? TableSortDirection.Ascending
        : TableSortDirection.Descending
    );
  };

  const renderValue = (obj: any) => {
    if (obj instanceof Date) return obj.toLocaleDateString();
    if (typeof obj === "function") return renderValue(obj());
    return obj;
  };

  const renderKeyValue = (obj: any, key: string) => {
    return renderValue(dataSet?.cellContent?.(key, obj) ?? obj[key]);
  };

  const cellIsSortable = (key: string): boolean => {
    if (typeof dataSet.sortable === "boolean") return dataSet.sortable;
    if (Array.isArray(dataSet.sortable)) return dataSet.sortable.includes(key);
    if (typeof dataSet.sortable === "function") return dataSet.sortable(key);
    return false;
  };

  const rowIsSelectable = (item: any): boolean => {
    if (typeof dataSet.selectable === "boolean") return dataSet.selectable;
    if (typeof dataSet.selectable === "function")
      return dataSet.selectable(item);
    return false;
  };

  const anyRowIsSeletable = () => {
    return sortedData.map((di) => rowIsSelectable(di)).includes(true);
  };

  const getEmptyContent = () => {
    if (dataSet?.emptyStateContent) {
      if (typeof dataSet.emptyStateContent === "function")
        return dataSet.emptyStateContent();

      return dataSet.emptyStateContent;
    }
    return null;
  };

  const getHeaderContent = (key: string) => {
    if (dataSet.headerContent) {
      if (typeof dataSet.headerContent === "function")
        return dataSet.headerContent(key);
      if (dataSet.headerContent instanceof Map)
        return dataSet.headerContent.has(key)
          ? dataSet.headerContent.get(key)
          : key;
    }
    return key;
  };

  return dataSet &&
    (dataSet?.loading ||
      ((dataSet?.data ?? []).length && dataSet?.emptyStateContent) == 0) ? (
    <Card className="table__content-holder">
      <CardBody>
        {dataSet?.loading ? (
          <div className="table__content-holder__loader">
            <Spinner />
          </div>
        ) : (
          getEmptyContent()
        )}
      </CardBody>
    </Card>
  ) : (
    <table className={tableClasses} {...props} ref={ref}>
      {dataSet ? (
        <>
          <TableHead>
            <TableRow isHeader={true}>
              {anyRowIsSeletable() ? (
                <TableHeaderCell>
                  {(dataSet?.selectionType ?? TableSelectionType.Multiple) ==
                    TableSelectionType.Multiple && (
                    <Checkbox
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        const newSelectedItems = evt.target.checked
                          ? dataSet.data
                          : [];
                        setSelectedItems(newSelectedItems);
                        if (dataSet.onSelectionChanged) {
                          dataSet.onSelectionChanged(newSelectedItems);
                        }
                      }}
                      disabled={false}
                      error={false}
                    />
                  )}
                </TableHeaderCell>
              ) : null}
              {headers.map((key, ind) => (
                <TableHeaderCell
                  key={ind}
                  onClick={() => {
                    cellHeaderHandler(key);
                  }}
                  sortDirection={
                    key == sortHeader ? sortDirection : TableSortDirection.None
                  }
                  sortable={cellIsSortable(key)}
                >
                  {getHeaderContent(key)}
                </TableHeaderCell>
              ))}
              {dataSet.actions && dataSet.actions.length ? (
                <TableHeaderCell>&nbsp;</TableHeaderCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((di, ind) => (
              <TableRow key={ind}>
                {rowIsSelectable(di) ? (
                  <TableCell>
                    {(dataSet?.selectionType ?? TableSelectionType.Multiple) ==
                    TableSelectionType.Multiple ? (
                      <Checkbox
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const newSelectedItems = evt.target.checked
                            ? [...selectedItems, di]
                            : selectedItems.filter((i) => i != di);
                          setSelectedItems(newSelectedItems);
                          if (dataSet.onSelectionChanged) {
                            dataSet.onSelectionChanged(newSelectedItems);
                          }
                        }}
                        checked={selectedItems.includes(di)}
                        disabled={false}
                        error={false}
                      />
                    ) : (
                      <Radio
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const newSelectedItems = [di];
                          setSelectedItems(newSelectedItems);
                          if (dataSet.onSelectionChanged) {
                            dataSet.onSelectionChanged(newSelectedItems);
                          }
                        }}
                        checked={selectedItems.includes(di)}
                        disabled={false}
                        error={false}
                      />
                    )}
                  </TableCell>
                ) : null}
                {headers.map((header, ind) => (
                  <TableCell
                    key={ind}
                    onClick={() => {
                      if (dataSet.onItemClicked) {
                        dataSet.onItemClicked(di);
                      }
                    }}
                  >
                    {renderKeyValue(di, header)}
                  </TableCell>
                ))}
                {dataSet.actions && dataSet.actions.length ? (
                  <TableCell buttons={true}>
                    <OverflowMenu
                      onItemSelected={(index, menuItem) => {
                        menuItem.action(di);
                      }}
                      items={
                        typeof dataSet.actions == "function"
                          ? dataSet.actions(di)
                          : dataSet.actions
                      }
                      direction={FloatMenuDirection.Left}
                    ></OverflowMenu>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </>
      ) : (
        children
      )}
    </table>
  );
});

export default Table;
