import React from "react"
import { ProgressProps, ProgressOrientation, ProgressStepState } from "./Progress.types"
import classnames from "classnames"

export const Progress: React.FC<ProgressProps> = React.forwardRef<HTMLDivElement, ProgressProps>(({ steps, className, orientation, ...props }, ref) => {
    orientation = orientation ?? ProgressOrientation.Veritical;

    const progressClasses = classnames('progress', {
        'progress--small': orientation === ProgressOrientation.Horizontal,
    }, className)
    return <div
        className={progressClasses}
        {...props}
        ref={ref}
    >
        {
            steps.map((s, i) => 
            <div
                key={i}
                className={classnames("progress__step", {
                    'progress__step--complete': s.state == ProgressStepState.Complete,
                    'progress__step--active': s.state == ProgressStepState.Active,
                })}
            >
                {
                    orientation == ProgressOrientation.Veritical &&
                    <div
                        className={classnames("progress__stepcontent", {
                            'progress__step--complete': s.state == ProgressStepState.Complete
                        })}
                    >
                        <div
                            className={classnames("progress__badge", {
                                'progress__badge--complete': s.state == ProgressStepState.Complete,
                                'progress__badge--active': s.state == ProgressStepState.Active,
                            })}
                        ></div>
                        {s.title}
                    </div>
                }
            </div>
            )
        }
    </div>
});

export default Progress;