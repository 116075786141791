import React from "react";
import classnames from "classnames";
import { RadioProps } from "./Radio.types";

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ className, error, disabled, ...props }: RadioProps, ref) => {
    const checkboxClasses = classnames(
      "control",
      "control--radio",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <label className={checkboxClasses} aria-label="radio">
        <input {...props} disabled={disabled} type="radio" ref={ref} />
        <div className="control__radio"></div>
      </label>
    );
  }
);

export default Radio;
