import React from "react";
import classnames from "classnames";
import { SwitchProps } from "./Switch.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, error, disabled, ...props }: SwitchProps, ref) => {
    const checkboxClasses = classnames(
      "control",
      "control--switch",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <label className={checkboxClasses} aria-label="switch">
        <input {...props} disabled={disabled} type="checkbox" ref={ref} />
        <div className="control__switch">
          <Icon
            icon={IconKeys.Check}
            className="text-white control__switchIcon"
          />
        </div>
      </label>
    );
  }
);

export default Switch;
