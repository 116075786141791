import React, { useState, FunctionComponent } from "react";
import classnames from "classnames";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { PolicyProps, PolicyItemProps } from "./Policy.types";

export const Policy: FunctionComponent<PolicyProps> = React.forwardRef<
  HTMLDivElement,
  PolicyProps
>(({ className, value, items, ...props }: PolicyProps, ref) => {
  const policyClasses = classnames("policy", {}, className);

  return (
    <div {...props} className={policyClasses} ref={ref}>
      {items.map((i, index) => (
        <PolicyItem
          key={index}
          title={i.title}
          pattern={i.pattern}
          value={value}
        ></PolicyItem>
      ))}
    </div>
  );
});

export const PolicyItem: FunctionComponent<PolicyItemProps> = React.forwardRef<
  HTMLDivElement,
  PolicyItemProps
>(({ title, value, pattern }: PolicyItemProps, ref) => {
  let regex = pattern instanceof RegExp ? pattern : new RegExp(pattern);

  const policyItemClasses = classnames("policy__item", {
    "policy__item--complete": regex.test(value),
  });

  return (
    <div className={policyItemClasses} ref={ref}>
      {regex.test(value) ? (
        <Icon
          icon={IconKeys.Check}
          className="policy__icon--complete text-g800"
        ></Icon>
      ) : (
        <i className="policy__icon"></i>
      )}
      <span>{title}</span>
    </div>
  );
});

export default Policy;
