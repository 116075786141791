import React from "react";
import classnames from "classnames";
import { SpinnerProps } from "./Spinner.types";

export const Spinner: React.FC<SpinnerProps> = ({
  className,
  ...props
}: SpinnerProps) => {
  const spinnerClasses = classnames("spinner", className);

  return <div {...props} className={spinnerClasses}></div>;
};

export default Spinner;
