import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { AddressProps } from "./Address.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { useThirdPartyScript } from "../../../Hooks/useThirdPartyScript";
import Spinner from "../../Spinner/Spinner";
import Text from "../Text/Text";

export const Address = React.forwardRef<HTMLInputElement, AddressProps>(
  (
    {
      captureKey,
      options,
      fields,
      resultCallback,
      className,
      icon,
      error,
      disabled,
      placeholder,
      ...props
    }: AddressProps,
    ref
  ) => {
    const { loading: scriptLoading, error: scriptError } = useThirdPartyScript([
      "//services.postcodeanywhere.co.uk/css/address-3.91.css",
      "//services.postcodeanywhere.co.uk/js/address-3.91.js",
    ]);
    const [addressControl, setAddressControl] =
      useState<{
        destroy: () => {};
      } | null>(null);

    const outerRef = useRef<HTMLDivElement>();

    useEffect(() => {
      if (typeof window !== "undefined") {
        if (window["pca"] && outerRef?.current) {
          const input = outerRef.current.querySelector("input");
          const pca = window["pca"];
          let controlOptions = { key: captureKey, ...options };
          let controlFields = fields || [];
          controlFields.push({
            element: input,
            field: "",
            mode: pca.fieldMode.SEARCH,
          });
          let control = new window["pca"].Address(
            controlFields,
            controlOptions
          );
          setAddressControl(control);
          control.listen("populate", (data, alts, key) => {
            if (resultCallback) {
              resultCallback(data, alts, key);
            }
          });
          return () => {
            if (addressControl) {
              addressControl.destroy();
            }
          };
        }
      }
    }, [scriptLoading, captureKey]);

    if (scriptLoading) {
      return <Spinner />;
    }

    const addressClases = classnames("control--address-finder", className);
    return (
      <div ref={outerRef}>
        <Text
          {...props}
          className={addressClases}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          ref={ref}
        />
      </div>
    );
  }
);

export default Address;
