import React from "react";
import classnames from "classnames";
import { TextProps } from "./Text.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Text = React.forwardRef<HTMLInputElement, TextProps>(
  (
    {
      className,
      icon,
      error,
      type,
      disabled,
      placeholder,
      clearable,
      ...props
    }: TextProps,
    ref
  ) => {
    type = type ?? "text";
    const textClasses = classnames(
      "control",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <div className={textClasses}>
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
          ref={ref}
        ></input>
        {clearable ? (
          <Icon
            icon={IconKeys.XCircle}
            className={"text-n700 control__clearer"}
            onClick={(evt) => {
              evt.currentTarget.parentElement.querySelector("input").value = "";
            }}
          ></Icon>
        ) : null}
        {icon ? (
          typeof icon === "string" ? (
            <Icon icon={icon}></Icon>
          ) : icon?.icon ? (
            <Icon icon={icon.icon} className={icon.className}></Icon>
          ) : null
        ) : null}
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Text;
