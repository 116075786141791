import React from "react";
import classnames from "classnames";
import { SelectProps } from "./Select.types";
import Icon from "../../Icon/Icon";

export const Select: React.FC<SelectProps> = React.forwardRef<
  HTMLSelectElement,
  SelectProps
>(({ children, className, error, disabled, ...props }, ref) => {
  const checkboxClasses = classnames(
    "control",
    "control--select",
    {
      "control--disabled": disabled,
      "control--error": error,
    },
    className
  );
  return (
    <div className={checkboxClasses}>
      <select {...props} disabled={disabled} ref={ref}>
        {children}
      </select>
      {error ? (
        <Icon icon="alert-triangle-filled-16" className={"text-o600"}></Icon>
      ) : null}
    </div>
  );
});

export default Select;
