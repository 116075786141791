import React, {
  useState,
  KeyboardEvent,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { FloatMenuProps } from "./FloatMenu.types";
import classnames from "classnames";

export const FloatMenu: React.FC<FloatMenuProps> = React.forwardRef<
  HTMLDivElement,
  FloatMenuProps
>(
  (
    {
      onItemSelected,
      onActiveStateChanged,
      items,
      className,
      children,
      direction,
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState<boolean>(false);
    const [paddingHeight, setPaddingHeight] = useState<number>(0);
    const floatMenuClasses = classnames(
      "float-menu",
      {
        "float-menu--active": active,
      },
      className
    );

    const handleEscapePress = useCallback(
      (evt) => {
        if (evt.key === "Escape") {
          if (active) {
            closeMenu();
          }
        }
      },
      [active]
    );

    const toggleMenu = (containerSize: number) => {
      setPaddingHeight(containerSize + 16);
      setActive(!active);
      onActiveStateChanged?.(!active);
    };

    const closeMenu = () => {
      setActive(false);
      onActiveStateChanged?.(false);
    };

    useEffect(() => {
      document.addEventListener("keyup", handleEscapePress);
      return () => {
        document.removeEventListener("keyup", handleEscapePress);
      };
    }, [handleEscapePress]);

    return (
      <div
        onClick={(evt) => {
          const container = evt.currentTarget;
          toggleMenu(container.getBoundingClientRect().height);
        }}
        onKeyUp={(evt: KeyboardEvent<HTMLDivElement>) => {
          if (evt.key === "Enter") {
            const container = evt.currentTarget;
            toggleMenu(container.getBoundingClientRect().height);
          }
        }}
        onMouseLeave={() => {
          closeMenu();
        }}
        className={floatMenuClasses}
        {...props}
        ref={ref}
        tabIndex={0}
      >
        {children}
        <div
          className={classnames("float-menu__menu", {
            "float-menu__menu--active": active,
            "float-menu__menu--right": direction === "right",
          })}
          style={{
            paddingTop: `${paddingHeight}px`,
          }}
        >
          <ul className="float-menu__menuitems">
            {items.map((item, index) => (
              <li
                key={index}
                className={`float-menu__menuitem ${
                  item.disabled ? "float-menu__menuitem--disabled" : ""
                } ${item.danger ? "float-menu__menuitem--danger" : ""}`}
              >
                {item.disabled ? (
                  item.title
                ) : (
                  <span
                    onClick={() => {
                      onItemSelected ? onItemSelected(index, item) : null;
                      item.onSelected ? item.onSelected() : null;
                    }}
                    className="float-menu__link"
                    tabIndex={0}
                  >
                    {item.title}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);

export default FloatMenu;
