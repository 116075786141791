import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../Base/Components";

export interface GridProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  size?: GridSizes;
}

export enum GridSizes {
  Adaptive = "adaptive",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export interface GridableComponentProps {
  rowSpans?: GridSpan[];
  colSpans?: GridSpan[];
}

export interface GridSpan {
  size?: GridSizes;
  start?: number;
  end?: number;
}

enum GridDirections {
  row = "row",
  column = "column",
}

export const getGridClasses = (
  colSpans: GridSpan[],
  rowSpans: GridSpan[]
): string[] => {
  const classes = [];
  rowSpans &&
    classes.push(rowSpans.map((r) => buildClasses(GridDirections.row, r)));
  colSpans &&
    classes.push(colSpans.map((c) => buildClasses(GridDirections.column, c)));
  return [].concat(...classes);
};

const buildClasses = (direction: GridDirections, span: GridSpan): string[] => {
  var classes = [];
  span.start &&
    classes.push(
      `grid-${direction}-${span.size ? `${span.size}-` : ""}start-${span.start}`
    );
  span.end &&
    classes.push(
      `grid-${direction}-${span.size ? `${span.size}-` : ""}end-${span.end}`
    );
  return classes;
};
