import React from "react";
import { PillProps, PillType, PillShape } from "./Pill.types";
import classnames from "classnames";
import Icon from "../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Pill: React.FC<PillProps> = React.forwardRef<
  HTMLDivElement,
  PillProps
>(
  (
    {
      children,
      removable,
      outlined,
      shape,
      type,
      onRemoveClicked,
      className,
      ...props
    },
    ref
  ) => {
    type = type ?? PillType.Info;
    shape = shape ?? PillShape.Rounded;
    const pillClasses = classnames(
      "pill",
      {
        "pill--info": type == PillType.Info,
        "pill--success": type == PillType.Success,
        "pill--error": type == PillType.Error,
        "pill--warn": type == PillType.Warn,
        "pill--square": shape == PillShape.Square,
        "pill--light": type == PillType.Light,
        "pill--dark": type == PillType.Dark,
        "pill--outlined": outlined,
      },
      className
    );
    return (
      <div
        className={pillClasses}
        tabIndex={0}
        {...props}
        ref={ref}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          onRemoveClicked?.();
        }}
        onKeyDown={(e) => {
          if (e.key === "Backspace") {
            onRemoveClicked?.();
          }
        }}
      >
        {children}
        {removable ? (
          <Icon
            className="pill__icon icon--smaller cursor--pointer"
            icon={IconKeys.X}
            unwrapped={true}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Pill;
