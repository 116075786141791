import React, { useEffect, useRef } from "react";
import { init } from "@gbg/gbgcomponentlibrary/src/components/atoms/logos/logos";

import classnames from "classnames";
import { LogoProps } from "./Logo.types";

export const Logo = React.forwardRef<HTMLElement, LogoProps>(
  ({ className, unwrapped, logo, ...props }: LogoProps, ref) => {
    const logoClasses = classnames(
      `logo`,
      `logo-${logo}`,
      {
        "logo--unwrapped": unwrapped,
      },
      className
    );
    const containerRef = useRef<HTMLElement>(null);

    useEffect(() => {
      const logoEl = containerRef.current;
      if (logoEl != null) {
        logoEl.innerHTML = "";
        init(logoEl);
      }
    });

    return <i ref={containerRef} className={logoClasses} {...props}></i>;
  }
);

export default Logo;
