import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";

export interface FloatMenuProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  items: FloatMenuItem[];
  onItemSelected?: (index: number, item: FloatMenuItem) => void;
  onActiveStateChanged?: (isActive: boolean) => void;
  direction?: FloatMenuDirection;
}

export interface FloatMenuItem
  extends IExtendableElement<HTMLAttributes<HTMLLIElement>> {
  title: string;
  onSelected?: () => void;
  disabled?: boolean;
  danger?: boolean;
}

export enum FloatMenuDirection {
  Right = "right",
  Left = "left",
}
