import React from "react";
import { BreadcrumbProps } from "./Breadcrumb.types";
import classnames from "classnames";

export const Breadcrumb = React.forwardRef<HTMLUListElement, BreadcrumbProps>(
  ({ crumbs, className, ...props }: BreadcrumbProps, ref) => {
    const breadcrumbClasses = classnames("breadcrumb", className);
    return (
      <ul {...props} className={breadcrumbClasses} ref={ref}>
        {crumbs.map((c, i) => (
          <li
            key={i}
            className={classnames("breadcrumb__crumb", {
              "breadcrumb__crumb--current": !c.link,
            })}
          >
            {typeof c.link == "string" && (
              <a href={c.link} className="breadcrumb__link">
                {c.title}
              </a>
            )}
            {React.isValidElement(c.link) &&
              React.cloneElement(c.link, { className: "breadcrumb__link" })}
            {!c.link && c.title}
          </li>
        ))}
      </ul>
    );
  }
);

export default Breadcrumb;
