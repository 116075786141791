import React, { Children } from "react";
import {
  InlineAlertBarProps,
  InlineAlertBarType,
} from "./InlineAlertBar.types";
import classnames from "classnames";
import Icon from "../../Atoms/Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const InlineAlertBar: React.FC<InlineAlertBarProps> = React.forwardRef<
  HTMLDivElement,
  InlineAlertBarProps
>(
  (
    {
      children,
      dismissable,
      onDismissed,
      icon,
      type,
      title,
      description,
      className,
      ...props
    },
    ref
  ) => {
    const alertBarClasses = classnames(
      "alert-bar",
      "alert-bar--inline",
      {
        "alert-bar--help": type == InlineAlertBarType.Help,
        "alert-bar--success": type == InlineAlertBarType.Success,
        "alert-bar--warn": type == InlineAlertBarType.Warn,
      },
      className
    );
    dismissable = dismissable ?? false;
    switch (type) {
      case InlineAlertBarType.Success:
        icon = icon ?? IconKeys.CheckCircle;
        break;
      case InlineAlertBarType.Warn:
        icon = icon ?? IconKeys.AlertTriangle;
        break;
      default:
        icon = icon ?? IconKeys.Info;
        break;
    }
    return (
      <div className={alertBarClasses} {...props} ref={ref}>
        {<Icon icon={icon} className="alert-bar__icon icon--smaller"></Icon>}
        {children}
        {dismissable ? (
          <div
            className="alert-bar__closer"
            onClick={() => {
              onDismissed ? onDismissed() : null;
            }}
          >
            <Icon icon={IconKeys.X} className={"icon--smaller"}></Icon>
          </div>
        ) : null}
      </div>
    );
  }
);

export default InlineAlertBar;
