import React, { useState } from "react";

import classnames from "classnames";
import { TagInputProps } from "./TagInput.types";
import Text from "../../Atoms/Controls/Text/Text";
import { button } from "@storybook/addon-knobs";
import Button from "../../Atoms/Button/Button";
import Pill from "../../Atoms/Pills/Pill";

export const TagInput: React.FC<TagInputProps> = React.forwardRef<
  HTMLDivElement,
  TagInputProps
>(
  (
    {
      className,
      tags = [],
      onTagAdded,
      onTagRemoved,
      buttonText = "Add",
      field,
      button,
      pill,
      inlineTags,
      allowDuplicates,
      ...props
    },
    ref
  ) => {
    const [innerTags, setInnerTags] = useState<string[]>(tags);
    const [value, setValue] = useState<string>((field?.value as string) ?? "");
    const handleAdd = () => {
      if (!allowDuplicates && innerTags.includes(value)) return;
      setInnerTags([...innerTags, value]);
      onTagAdded?.(value, innerTags);
      setValue("");
    };
    const tagInputClasses = classnames("tag-input", className);
    return (
      <div className={tagInputClasses} {...props} ref={ref}>
        <div className="tag-input__input">
          <Text
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAdd();
              }
            }}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              setValue(evt.target.value);
            }}
            {...field}
            value={value}
          />
          <Button
            {...button}
            onClick={() => {
              handleAdd();
            }}
          >
            {buttonText}
          </Button>
        </div>
        <div
          className={classnames("tag-input__tags", {
            "tag-input__tags--inline": inlineTags,
          })}
        >
          {innerTags.map((tag, index) => (
            <Pill
              key={index}
              removable={pill?.removable ?? true}
              {...(typeof pill == "function" ? pill(tag) : pill)}
              onRemoveClicked={() => {
                setInnerTags(innerTags.filter((t, i) => i != index));
                onTagRemoved?.(tag, innerTags);
              }}
            >
              {tag}
            </Pill>
          ))}
        </div>
      </div>
    );
  }
);

export default TagInput;
