import { IExtendableElement } from "../../Base/Components";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { HTMLAttributes } from "react";

export interface AlertBarProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: AlertBarType;
  className?: string;
  icon?: IconKeys | string;
}

export interface AlertBarTextProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  title?: string;
  text?: string;
}

export enum AlertBarType {
  Info = "info",
  Success = "success",
  Warn = "warn",
  Error = "error",
}
