import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface AccordionItemProps {
  children: React.ReactNode;
  header: string;
  isActive?: boolean;
}

import { AccordionContext } from './Accordion';
import Label from '../Controls/Labels/Label';
export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  header,
  isActive = false,
  ...props
}) => {
  const {
    alwaysOpen,
    applyTransition,
    accordionRef,
    accordionItemState: items,
    setState: setItems,
  } = useContext(AccordionContext);
  const headerRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(isActive);

  const hash = useMemo(() => {
    return Math.random().toString(36).substring(2, 9);
  }, []);

  useEffect(() => {
    if (!(hash in items)) {
      setItems({ ...items, [hash]: setActive });
    }
  }, [items, setItems, hash]);

  const toggleAccordion = () => {
    setActive(!active);
    if (!alwaysOpen && !active) toggleInactiveItems();
  };

  const toggleInactiveItems = () => {
    const parentAccordionWrapper = accordionRef?.current;
    if (!parentAccordionWrapper) return;
    const headers = (
      parentAccordionWrapper as HTMLDivElement
    ).querySelectorAll<HTMLDivElement>(`div[aria-expanded='true']`);

    if (headers) {
      headers.forEach((item) => {
        if (item && item !== headerRef.current) {
          const itemHash = item.id.split('-')[2];
          items[itemHash](false);
        }
      });
    }
  };

  return (
    <div className='accordion__item' {...props}>
      <div
        id={`accordion-header-${hash}`}
        ref={headerRef}
        aria-expanded={active}
        aria-controls={`content-${hash}`}
        onClick={toggleAccordion}
        className={`accordion__header ${
          active ? 'accordian__header--expanded' : ''
        }`}
      >
        <Label className='accordion__header__label' htmlFor={`content-${hash}`}>
          {header}
        </Label>
        {/* <label>{header}</label> */}
      </div>
      <div
        id={`content-${hash}`}
        aria-labelledby={`accordion-header-${hash}`}
        className={`accordion__content__wrapper ${
          applyTransition ? 'accordion__content__wrapper--transition' : ''
        } ${active ? 'accordion__content__wrapper--active' : ''}`}
      >
        <div className='accordion__content'>{children}</div>
      </div>
    </div>
  );
};

export default memo(AccordionItem);
