import React, { useEffect, useRef } from "react";
import { init } from "@gbg/gbgcomponentlibrary/src/components/atoms/icons/icons";

import classnames from "classnames";
import { IconProps } from "./Icon.types";

export const Icon = React.forwardRef<HTMLElement, IconProps>(
  ({ className, unwrapped, icon, style, ...props }: IconProps, ref) => {
    const iconClasses = classnames(
      `icon`,
      `ico-${icon}`,
      {
        "icon--unwrapped": unwrapped,
      },
      className
    );
    const containerRef = useRef<HTMLElement>(null);

    useEffect(() => {
      const iconEl = containerRef.current;
      if (iconEl != null) {
        iconEl.innerHTML = "";
        init(iconEl);
      }
    }, [icon, style, className]);

    return (
      <i
        ref={containerRef}
        style={style}
        className={iconClasses}
        {...props}
      ></i>
    );
  }
);

export default Icon;
