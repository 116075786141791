import React from "react";
import classnames from "classnames";
import { TableCellProps } from "./TableCell.types";

export const TableCell: React.FC<TableCellProps> = React.forwardRef<
  HTMLTableCellElement,
  TableCellProps
>(
  (
    { children, isExpander, isExpansion, buttons, className, ...props },
    ref
  ) => {
    const tableCellClasses = classnames(
      `table__cell`,
      {
        "table__cell--buttons": buttons,
        "table__cell--expander": isExpander,
        "table__cell--expansion": isExpansion,
      },
      className
    );

    return (
      <td className={tableCellClasses} {...props} ref={ref}>
        {children}
      </td>
    );
  }
);

export default TableCell;
