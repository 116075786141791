import React from 'react'
import classnames from 'classnames';
import { TableHeaderCellProps } from './TableHeaderCell.types';
import { TableSortDirection } from '../Table/Table.types';



export const TableHeaderCell: React.FC<TableHeaderCellProps> = React.forwardRef<HTMLTableCellElement,TableHeaderCellProps>(({ children, sortable, sortDirection, className, ...props}, ref) => {
    const tableCellClasses = classnames(`table__cell`, 'table__cell--header', className);

    let sortableClassNames = classnames("table__cell--sortable",
        {
            "table__cell--sortedasc": sortDirection == TableSortDirection.Ascending,
            "table__cell--sorteddesc": sortDirection == TableSortDirection.Descending
        }
    );

    return <th
            className={tableCellClasses}
            {...props}
            ref={ref}
            >
        {
            sortable ? 
            <div
                className={sortableClassNames}
            >
                {children}
            </div>
            :
            children
        }
    </th>;   
});

export default TableHeaderCell;