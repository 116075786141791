import React from "react";
import classnames from "classnames";
import { PrimaryNavigationItemProps } from "./Item.types";

export const Item: React.FC<PrimaryNavigationItemProps> = React.forwardRef<
  HTMLLIElement,
  PrimaryNavigationItemProps
>(({ active, children, link, className, ...props }, ref) => {
  const itemClasses = classnames(
    "primary-nav__item",
    {
      "primary-nav__item--active": active,
    },
    className
  );
  return (
    <li className={itemClasses} {...props} ref={ref}>
      <a className="primary-nav__link" tabIndex={0} href={link}>
        <div className="primary-nav__linktext">{children}</div>
      </a>
    </li>
  );
});

export default Item;
