import React, { useState } from "react"
import classnames from "classnames"
import { TopBarItemProps, TopBarItemType } from "./TopBarItem.types"


export const TopBarItem: React.FC<TopBarItemProps> = React.forwardRef<HTMLDivElement, TopBarItemProps>(({ className, title, subtitle, type, ...props }, ref) => {
    
    type = type ?? TopBarItemType.Information;

    const tobBarItemClasses = classnames({
        'top-bar__information': type == TopBarItemType.Information
    }, className);

    return <div className={tobBarItemClasses} {...props} ref={ref}>
                { title ? <div className="top-bar__informationtitle">{title}</div> : null }
                { subtitle ? <div className="top-bar__informationsubtitle">{subtitle}</div> : null }
            </div>;
})

export default TopBarItem;