import React from "react";
import classnames from "classnames";
import { SkeletonProps, SkeletonVariant } from "./Skeleton.types";

export const Skeleton: React.FC<SkeletonProps> = React.forwardRef<
  HTMLDivElement,
  SkeletonProps
>(
  (
    {
      className,
      ...props
    },
    ref
  ) => {
    const variant = props.variant;
    let skeletonClasses = classnames( 'skeleton', { [`skeleton--${variant}`]: variant, }, className );

    switch(variant) {
      case SkeletonVariant.Paragraph:
        skeletonClasses = classnames('skeleton-paragraph', className);
        return (
          <div className={skeletonClasses} {...props}>
            <div className={"skeleton"} style={{width: "65%"}}></div>
            <div className={"skeleton"} style={{width: "87%"}}></div>
            <div className={"skeleton"} style={{width: "73%"}}></div>
          </div>
        )
      default:
        return (
          <div className={skeletonClasses} {...props}></div>
        )
    }
  }
);

export default Skeleton;
