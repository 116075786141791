import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { AssistiveProps } from './Assistive.types';



export const Assistive: FunctionComponent<AssistiveProps> = React.forwardRef<HTMLParagraphElement, AssistiveProps>(({ children, className, ...props }, ref) => {
    const labelClasses = classnames('label', 'label label--assistive', className);
    return <p 
        className={labelClasses}
        {...props}
        ref={ref}>
            {children}
        </p>
});

export default Assistive;