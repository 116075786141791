import React, { FunctionComponent } from 'react'

import classnames from 'classnames'
import { ErrorTextProps } from './ErrorText.types';




export const ErrorText: FunctionComponent<ErrorTextProps> = React.forwardRef<HTMLParagraphElement, ErrorTextProps>(({ children, className, ...props }, ref) => {
    const labelClasses = classnames('error-text', className);
    return <p 
        className={labelClasses}
        {...props}
        ref={ref}>
            {children}
        </p>
});

export default ErrorText;