import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";

export interface TopBarItemProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: TopBarItemType;
  title?: string;
  subtitle?: string;
}

export enum TopBarItemType {
  Information,
}
