import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import classnames from "classnames";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);
import { histogram } from "@gbg/gbgcomponentlibrary/src/components/atoms/visualisation/charts/histogram/histogram";
import { ChartSets, ColorSets } from "@gbg/gbgcomponentlibrary/src/_colorSets";
import { arrInfIndex } from "@gbg/gbgcomponentlibrary/src/_utilities";
import { ChartProps } from "./Chart.types";

export const Chart: React.FC<ChartProps> = React.forwardRef<
  ChartJS | undefined,
  ChartProps
>(({ config, rerender, className, ...props }, ref) => {
  const [chart, setChart] = useState<ChartJS>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useImperativeHandle<ChartJS | undefined, ChartJS | undefined>(
    ref,
    () => chart,
    [chart]
  );

  const destroyChart = () => {
    if (chart) chart.destroy();
  };

  const render = () => {
    setChart(new ChartJS(canvasRef.current, config));
  };
  const update = () => {
    if (!chart) return;

    const { datasets: newData, ...newChartData } = config.data;
    const { datasets: currentData } = chart.config.data;

    Object.assign(chart.config.data, newChartData);

    chart.config.data.datasets = newData.map((nds, i) => {
      const currentSet = currentData.find((d) => d.label == nds.label);
      if (!currentSet || !nds.data) return { ...nds };
      if (!currentSet.data) {
        currentSet.data = [];
      } else {
        currentSet.data.length = nds.data.length;
      }

      Object.assign(currentSet.data, nds.data);
      Object.assign(currentSet, { ...nds, data: currentSet.data });

      return currentSet;
    });

    chart.update();
  };

  useEffect(() => {
    render();
    return () => destroyChart();
  }, []);

  useEffect(() => {
    if (rerender) {
      destroyChart();
      setTimeout(() => {
        render();
      }, 0);
    } else {
      update();
    }
  }, [config]);

  const histogramClasses = classnames("chart", className);
  return (
    <canvas ref={canvasRef} className={histogramClasses} {...props}></canvas>
  );
});

export default Chart;
