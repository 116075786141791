import React, { useState } from "react";
import { toHtmlId } from "@gbg/gbgcomponentlibrary/src/_utilities";
import classnames from "classnames";
import {
  ToggleButtonGroupProps,
  ToggleButtonGroupSize,
} from "./ToggleButtonGroup.types";
import { Icon } from "../../Icon/Icon";

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = React.forwardRef<
  HTMLFieldSetElement,
  ToggleButtonGroupProps
>(
  (
    {
      title,
      size,
      buttons,
      onButtonSelected,
      selectedIndex,
      className,
      ...props
    },
    ref
  ) => {
    const [selectedButton, setSelectedButton] = useState(selectedIndex ?? 0);
    const toggleButtonGroupClasses = classnames(
      "button-toggle-group",
      {
        "button-toggle-group--small": size === ToggleButtonGroupSize.Small,
      },
      className
    );

    const buttonClickHandler = (buttonIndex: number) => {
      setSelectedButton(buttonIndex);
      if (onButtonSelected) {
        onButtonSelected(buttonIndex);
      }
    };

    return (
      <fieldset className={toggleButtonGroupClasses} {...props} ref={ref}>
        <legend className="a11y-hidden">{title}</legend>
        {buttons.map((b, i) => (
          <React.Fragment key={`item_${i}`}>
            <input
              key={`input_${i}`}
              className="button-toggle-group__input"
              type="radio"
              name={title}
              id={toHtmlId(b.title)}
              value={b.title}
              checked={i == selectedButton}
              onChange={() => {
                buttonClickHandler(i);
              }}
            />
            <label
              key={`label_${i}`}
              className="button-toggle-group__button"
              htmlFor={toHtmlId(b.title)}
            >
              {b.title}
              {b.icon ? (
                <Icon icon={b.icon} className={"button__icon--right"}></Icon>
              ) : null}
            </label>
          </React.Fragment>
        ))}
      </fieldset>
    );
  }
);

export default ToggleButtonGroup;
