import React from "react";
import classnames from "classnames";
import { CheckboxProps } from "./Checkbox.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, error, disabled, ...props }: CheckboxProps, ref) => {
    const checkboxClasses = classnames(
      "control",
      "control--checkbox",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <label className={checkboxClasses} aria-label="checkbox">
        <input {...props} disabled={disabled} type="checkbox" ref={ref} />
        <div className="control__checkbox">
          <Icon icon={IconKeys.Check} className="text-white" />
        </div>
      </label>
    );
  }
);

export default Checkbox;
