import React from "react";
import {
  ButtonProps,
  ButtonKind,
  ButtonSize,
  ButtonWorkerPosition,
  ButtonAnimation,
} from "./Button.types";
import classnames from "classnames";
import { Icon } from "../Icon/Icon";

export const Button: React.FC<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      type,
      worker,
      workerPosition,
      inTable,
      active,
      children,
      iconContent,
      size,
      iconAfter,
      iconBefore,
      kind,
      className,
      disabled,
      animation,
      ...props
    },
    ref
  ) => {
    kind = kind ?? ButtonKind.Primary;
    const buttonClasses = classnames(
      "button",
      {
        "button--primary": kind === ButtonKind.Primary,
        "button--secondary": kind === ButtonKind.Secondary,
        "button--tertiary": kind === ButtonKind.Tertiary,
        "button--destructive": kind === ButtonKind.Destructive,
        "button--secondary-destructive":
          kind == ButtonKind.SecondaryDestructive,
        "button--small": size === ButtonSize.Small,
        "button--worker": worker,
        "button--worker-right": workerPosition == ButtonWorkerPosition.Right,
        "button--worker-left": workerPosition == ButtonWorkerPosition.Left,
        "button--active": active,
        "button--table": inTable,
        "button--animation-hover-icon-right":
          animation == ButtonAnimation.HoverIconRight,
        "button--animation-hover-icon-left":
          animation == ButtonAnimation.HoverIconLeft,
      },
      className
    );
    return (
      <button
        data-testid="button-component"
        disabled={disabled}
        className={buttonClasses}
        type={type ?? "button"}
        {...props}
        ref={ref}
      >
        {iconBefore ? (
          <div className="button__icon--left">
            <Icon icon={iconBefore}></Icon>
          </div>
        ) : null}
        {children}
        {iconAfter ? (
          <div className="button__icon--right">
            <Icon icon={iconAfter}></Icon>
          </div>
        ) : null}
      </button>
    );
  }
);

export default Button;
