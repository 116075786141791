import React, { useState, KeyboardEvent } from "react";
import classnames from "classnames";
import { TabBarProps } from "./TabBar.types";

export const TabBar: React.FC<TabBarProps> = React.forwardRef<
  HTMLUListElement,
  TabBarProps
>(({ tabs, onTabSelected, selectedIndex, className, ...props }, ref) => {
  const [selectedTab, setSelectedTab] = useState(selectedIndex ?? 0);
  const tabBarClasses = classnames("tab-bar", {}, className);

  const tabClickHandler = (tabIndex: number) => {
    setSelectedTab(tabIndex);
    if (onTabSelected) {
      onTabSelected(tabIndex);
    }
  };

  return (
    <ul className={tabBarClasses} {...props} ref={ref}>
      {tabs.map((t, i) => (
        <li
          data-tag={t}
          key={t}
          tabIndex={0}
          onClick={() => tabClickHandler(i)}
          onKeyUp={(evt: KeyboardEvent) => {
            if (evt.key === "Enter") {
              tabClickHandler(i);
            }
          }}
          className={classnames("tab", {
            "tab--active": i === selectedTab,
          })}
        >
          {t}
        </li>
      ))}
    </ul>
  );
});

export default TabBar;
