import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
  AddressFormLabels,
  AddressFormProps,
  IAddress,
} from "./AddressForm.types";
import FormGroup from "../../Molecules/Forms/Groups/FormGroup";
import Label from "../../Atoms/Controls/Labels/Label";
import Assistive from "../../Atoms/Controls/Labels/Assistive";
import Address from "../../Atoms/Controls/Address/Address";
import Modal, { ModalBody } from "../../Molecules/Modal/Modal";
import Button from "../../Atoms/Button/Button";
import Text from "../../Atoms/Controls/Text/Text";
import { ButtonKind } from "../../Atoms/Button/Button.types";
import Select from "../../Atoms/Controls/Select/Select";
import { countryData } from "@gbg/gbgcomponentlibrary/src/data/countries";
import ErrorText from "../../Atoms/Controls/Labels/ErrorText";

export const AddressForm = React.forwardRef<HTMLInputElement, AddressFormProps>(
  (
    {
      address: preAddress,
      captureKey,
      options,
      fields,
      resultCallback,
      className,
      icon,
      error,
      disabled,
      labels,
      type,
      onAddressChanged,
      title = "Address",
      assistive = "Registered company address",
      preValidateAddress,
      includeManualAddress = true,
      ...props
    }: AddressFormProps,
    ref
  ) => {
    const [layout, setLayout] = useState<"UK" | "US">(
      preAddress?.CountryIso3 === "USA" ? "US" : "UK"
    );
    const addressClases = classnames("address-lookup-form", className);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const mergedLabels: AddressFormLabels = {
      company: "Company",
      line1: "Line 1",
      line2: "Line 2",
      line3: "Line 3",
      city: "Town",
      province: "County",
      state: "State",
      postalCode: "Postal code",
      postcode: "Postcode",
      country: "Country",
      cancel: "Cancel",
      confirm: "Save address",
      ...labels,
    };
    const getLabel = (address: IAddress) => {
      return [
        address.Company,
        address.Line1,
        address.Line2,
        address.Line3,
        address.City,
        address.Province,
        address.PostalCode,
        getCountryName(address.CountryIso3),
      ].filter((i) => i && i.trim() != "");
    };

    const getCountryName = (countryCode: string) => {
      return countryData.find((c) => c["alpha-3"] === countryCode)?.name ?? "";
    };

    const [label, setLabel] = useState<string[]>(
      preAddress ? getLabel(preAddress) : []
    );
    const [address, setAddress] = useState<IAddress>(preAddress);
    const [manualAddress, setManualAddress] = useState<IAddress>(null);

    const handleAddressResponse = (address: IAddress) => {
      if (preValidateAddress?.(address) ?? true) {
        setLabel(getLabel(address));
        setAddress(address);
        onAddressChanged?.(address);
      }
    };

    const handleManualValueChange =
      (key: string) =>
      (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setManualAddress({
          ...manualAddress,
          [key]: evt.target.value,
        });
      };

    return (
      <FormGroup {...props} className={addressClases}>
        <Label htmlFor="address_input">{title} *</Label>
        {assistive && <Assistive>{assistive}</Assistive>}
        <Address
          id="address_input"
          className={error ? "" : "m-m-b-2"}
          captureKey={captureKey}
          resultCallback={(addressData, alts, key) => {
            handleAddressResponse(addressData);
            if (resultCallback) {
              resultCallback(addressData, alts, key);
            }
          }}
          disabled={disabled}
          error={error}
          {...props}
        ></Address>
        {error && typeof error == "string" && (
          <ErrorText className="m-m-b-2">{error}</ErrorText>
        )}
        <p className="address-lookup__label m-m-b-2">
          {label.map((l, i) => (
            <React.Fragment key={i}>
              <span>{l}</span>
              <br />
            </React.Fragment>
          ))}
        </p>
        {includeManualAddress && (
          <>
            <a
              className="link"
              onClick={() => {
                setManualAddress(address);
                setModalVisible(true);
              }}
            >
              Enter manually
            </a>

            <Modal isVisible={modalVisible}>
              <ModalBody className="address__modal">
                <FormGroup>
                  <Label htmlFor="address__country">
                    {mergedLabels.country} *
                  </Label>
                  <Select
                    name="address__country"
                    data-address-mapping="CountryIso3"
                    data-field-mode="COUNTRY"
                    id="address__country"
                    value={manualAddress?.CountryIso3 ?? ""}
                    onChange={(...args) => {
                      const [evt] = args;
                      setLayout(
                        evt.target.value === "USA" || evt.target.value === "UMI"
                          ? "US"
                          : "UK"
                      );
                      handleManualValueChange("CountryIso3")(...args);
                    }}
                  >
                    {countryData.map((country) => (
                      <option
                        value={country["alpha-3"]}
                        key={country["alpha-3"]}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address__company">
                    {mergedLabels.company}
                  </Label>
                  <Text
                    id="address__company"
                    data-address-mapping="Company"
                    type="text"
                    value={manualAddress?.Company ?? ""}
                    onChange={handleManualValueChange("Company")}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address__line1">{mergedLabels.line1} *</Label>
                  <Text
                    id="address__line1"
                    data-address-mapping="Line1"
                    type="text"
                    value={manualAddress?.Line1 ?? ""}
                    onChange={handleManualValueChange("Line1")}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address__line2">{mergedLabels.line2}</Label>
                  <Text
                    id="address__line2"
                    data-address-mapping="Line2"
                    type="text"
                    value={manualAddress?.Line2 ?? ""}
                    onChange={handleManualValueChange("Line2")}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address__line3">{mergedLabels.line3}</Label>
                  <Text
                    id="address__line3"
                    data-address-mapping="Line3"
                    type="text"
                    value={manualAddress?.Line3 ?? ""}
                    onChange={handleManualValueChange("Line3")}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="address__town">{mergedLabels.city} *</Label>
                  <Text
                    id="address__town"
                    data-address-mapping="City"
                    type="text"
                    value={manualAddress?.City ?? ""}
                    onChange={handleManualValueChange("City")}
                  />
                </FormGroup>
                {layout === "US" ? (
                  <>
                    <FormGroup>
                      <Label htmlFor="address__county">
                        {mergedLabels.state} *
                      </Label>
                      <Select
                        id="address__county"
                        data-address-mapping="Province"
                        value={manualAddress?.Province ?? ""}
                        onChange={handleManualValueChange("Province")}
                      >
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                        <option value="AS">American Samoa</option>
                        <option value="GU">Guam</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="VI">Virgin Islands</option>
                        <option value="AA">Armed Forces Americas</option>
                        <option value="AP">Armed Forces Pacific</option>
                        <option value="AE">Armed Forces Others</option>
                      </Select>
                    </FormGroup>
                  </>
                ) : (
                  <>
                    <FormGroup>
                      <Label htmlFor="address__county">
                        {mergedLabels.province} *
                      </Label>
                      <Text
                        id="address__county"
                        data-address-mapping="Province"
                        type="text"
                        value={manualAddress?.Province ?? ""}
                        onChange={handleManualValueChange("Province")}
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <Label htmlFor="address__postcode">
                    {layout === "US"
                      ? mergedLabels.postalCode
                      : mergedLabels.postcode}{" "}
                    *
                  </Label>
                  <Text
                    id="address__postcode"
                    data-address-mapping="PostalCode"
                    type="text"
                    value={manualAddress?.PostalCode ?? ""}
                    onChange={handleManualValueChange("PostalCode")}
                  />
                </FormGroup>

                <Button
                  kind={ButtonKind.Secondary}
                  onClick={() => {
                    setModalVisible(false);
                  }}
                  className="m-m-t-4 m-m-r-1"
                >
                  Cancel
                </Button>
                <Button
                  className="m-m-t-4"
                  onClick={() => {
                    handleAddressResponse(manualAddress);
                    setModalVisible(false);
                  }}
                >
                  {mergedLabels.confirm}
                </Button>
              </ModalBody>
            </Modal>
          </>
        )}
      </FormGroup>
    );
  }
);

export default AddressForm;
