import React, { useState, KeyboardEvent, useCallback, useEffect } from "react";
import classnames from "classnames";
import { TopBarProps, TopBarBrand } from "./TopBar.types";
import Avatar from "../../Avatars/Avatar";
import { Icon } from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { FloatMenu } from "../FloatMenu/FloatMenu";
import Item from "../Primary/Item";
import { AvatarSize } from "../../Avatars/Avatar.types";
import Logo from "../../Logo/Logo";
import { LogoKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_logos";

export const TopBar: React.FC<TopBarProps> = React.forwardRef<
  HTMLDivElement,
  TopBarProps
>(
  (
    {
      includeNavLink,
      children,
      onNavigationToggle,
      className,
      brand,
      user,
      menu,
      lightLogo,
      ...props
    },
    ref
  ) => {
    brand = brand ?? TopBarBrand.GBG;
    includeNavLink = includeNavLink ?? true;
    const [menuActive, setMenuActive] = useState(false);
    const tobBarClasses = classnames("top-bar", {}, className);

    const menuClickHandler = () => {
      onNavigationToggle ? onNavigationToggle() : null;
    };

    return (
      <div {...props} className={tobBarClasses} ref={ref}>
        {includeNavLink ? (
          <div
            className="top-bar__menu-icon"
            onClick={() => {
              menuClickHandler();
            }}
            onKeyUp={(evt: KeyboardEvent) => {
              if (evt.key === "Enter") {
                menuClickHandler();
              }
            }}
            tabIndex={0}
          >
            Menu
          </div>
        ) : null}
        <div className="top-bar__logo">
          <Logo
            logo={
              brand == TopBarBrand.Loqate
                ? LogoKeys.Loqate
                : lightLogo
                ? LogoKeys.Gbg_light
                : LogoKeys.Gbg
            }
          />
        </div>
        <div className="top-bar__menu">
          {children}
          {user ? (
            <>
              <div className="top-bar__userdetails">
                <div className="top-bar__username">{user.username}</div>
                <div className="top-bar__companyname">{user.company}</div>
              </div>

              {menu ? (
                <FloatMenu
                  onActiveStateChanged={(isActive) => {
                    setMenuActive(isActive);
                    menu.onActiveStateChanged
                      ? menu.onActiveStateChanged(isActive)
                      : null;
                  }}
                  onItemSelected={menu.onItemSelected}
                  items={menu.items}
                  className="top-bar__menutarget"
                >
                  <div className="top-bar__avatar">
                    <Avatar
                      name={user.username}
                      size={AvatarSize.TopBar}
                    ></Avatar>
                  </div>
                  {menuActive ? (
                    <Icon
                      icon={IconKeys.ChevronUp}
                      className={classnames("top-bar__menuicon icon--smaller", {
                        "top-bar__menuicon--active": menuActive,
                      })}
                    ></Icon>
                  ) : (
                    <Icon
                      icon={IconKeys.ChevronDown}
                      className={classnames("top-bar__menuicon icon--smaller", {
                        "top-bar__menuicon--active": menuActive,
                      })}
                    ></Icon>
                  )}
                </FloatMenu>
              ) : (
                <div className="top-bar__avatar">
                  <Avatar
                    name={user.username}
                    size={AvatarSize.TopBar}
                  ></Avatar>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }
);

export default TopBar;
