import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../Base/Components";
import { CopyProps } from "../Copy/Copy.types";

export interface CodeSampleProps
  extends IExtendableElement<HTMLAttributes<HTMLPreElement>> {
  language: CodeSampleLanguage | string;
  className?: string;
  lineNumbers?: boolean;
  copy?: CopyProps;
}

export enum CodeSampleLanguage {
  javascript = "javascript",
  css = "css",
  markup = "markup",
  scss = "scss",
  bash = "bash",
  json = "json",
  swift = "swift",
  kotlin = "kotlin",
  java = "java",
}
