import { IExtendableElement } from "../../../Base/Components";
import React, { TableHTMLAttributes } from "react";

export interface TableProps<T = any>
  extends IExtendableElement<TableHTMLAttributes<HTMLTableElement>> {
  hasExpandableRows?: boolean;
  dataSet?: {
    loading?: boolean;
    emptyStateContent?: string | React.ReactNode | (() => React.ReactNode);
    selectable?: boolean | ((item: T) => boolean);
    selectionType?: TableSelectionType;
    actions?: RowAction<T>[] | ((item: T) => RowAction<T>[]);
    data: T[];
    sortable?: boolean | string[] | ((key: string) => boolean);
    onSelectionChanged?: (selectedItems: T[]) => void;
    cellFilter?: string[] | ((key: string) => boolean);
    headerContent?:
      | Map<string, string>
      | ((key: string) => React.ReactNode | string);
    cellContent?: (key: string, item: T) => React.ReactNode | any;
    initialSelection?:
      | T[]
      | number[]
      | ((dataItem: T, index: number) => boolean);
    onItemClicked?: (item: T) => void;
  };
}

export interface RowAction<T> {
  title: string;
  action: (dataItem: T) => void;
}

export enum TableSortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
  None = "None",
}

export enum TableSelectionType {
  Multiple = "Multiple",
  Single = "Single",
}
