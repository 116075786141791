import { IExtendableElement } from "../../../Base/Components";
import { HTMLAttributes, SelectHTMLAttributes } from "react";

export enum ActiveListState {
  Active = "Active",
  Inactive = "Inactive",
}

export interface ActiveListProps<T = any>
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  items: { active: T[]; inactive: T[] };
  onItemMoved?: (
    itemsMoved: T[],
    movedTo: ActiveListState,
    items: { active: T[]; inactive: T[] }
  ) => void;
  contentRenderer: (item: T) => React.ReactNode;
  searchValue?: (item: T) => string;
  searchActive?: boolean;
  searchInactive?: boolean;
  activeTitle: string;
  inactiveTitle: string;
  sort?: (a: T, b: T) => 1 | 0 | -1;
}
