import React from "react";
import {
  InlineNotificationProps,
  InlineNotificationType,
} from "./InlineNotification.types";
import classnames from "classnames";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import Icon from "../../../Atoms/Icon/Icon";

export const InlineNotification: React.FC<InlineNotificationProps> =
  React.forwardRef<HTMLDivElement, InlineNotificationProps>(
    (
      {
        children,
        onDismissed,
        icon,
        title,
        dismissable,
        type,
        className,
        ...props
      },
      ref
    ) => {
      const inlineNotificationClasses = classnames(
        "notification",
        "notification--toast",
        {
          "notification--info": type == InlineNotificationType.Info,
          "notification--success": type == InlineNotificationType.Success,
          "notification--warn": type == InlineNotificationType.Warn,
          "notification--error": type == InlineNotificationType.Error,
          "notification--neutral": type == InlineNotificationType.Neutral,
        },
        className
      );
      switch (type) {
        case InlineNotificationType.Success:
          icon = icon ?? IconKeys.CheckCircle;
          break;
        case InlineNotificationType.Warn:
          icon = icon ?? IconKeys.AlertTriangle;
          break;
        case InlineNotificationType.Error:
          icon = icon ?? IconKeys.XCircle;
          break;
        default:
          icon = icon ?? IconKeys.Info;
          break;
      }
      return (
        <div className={inlineNotificationClasses} {...props} ref={ref}>
          <Icon icon={icon} className="notification__icon icon--smaller"></Icon>
          <div className="notification__content">
            {title ? (
              <span className="notification__title">{title}</span>
            ) : null}
            {children}
          </div>
          {dismissable ? (
            <div
              className="notification__closer"
              onClick={() => {
                onDismissed ? onDismissed() : null;
              }}
            >
              <Icon icon={IconKeys.X} className={"icon--smaller"}></Icon>
            </div>
          ) : null}
        </div>
      );
    }
  );

export default InlineNotification;
