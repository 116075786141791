import { IExtendableElement } from "../../Base/Components";
import { ImgHTMLAttributes } from "react";

export interface AvatarProps
  extends IExtendableElement<ImgHTMLAttributes<HTMLImageElement>> {
  className?: string;
  size?: AvatarSize;
  name: string;
  image?: string;
}

export enum AvatarSize {
  Small = "small",
  Regular = "regular",
  TopBar = "topbar",
}
