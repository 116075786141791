import React from "react"
import { FieldsetProps } from "./Fieldset.types"
import classnames from "classnames";

export const Fieldset: React.FC<FieldsetProps> = React.forwardRef<HTMLFieldSetElement, FieldsetProps>(({ children, title, description, ...props }, ref) => {
    const fieldsetClasses = classnames("fieldset");
    return <fieldset
                className={fieldsetClasses}
                {...props}
                ref={ref}
            >
               
                <div className="fieldset__info">
                    {
                        title ?
                        <p className="fieldset__title">{title}</p>
                        : null
                    }
                    {
                        description ?
                        <p className="fieldset__description">{description}</p>
                        : null
                    }
                </div>
                <div className="fieldset__control">
                    {children}
                </div>
            
            </fieldset>;
})

export default Fieldset;