import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";

export interface FormGroupProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: FormGroupType;
}

export enum FormGroupType {
  Standard = "standard",
  Checkbox = "checkbox",
  Radio = "radio",
  CheckboxList = "checkboxlist",
  RadioList = "radiolist",
}

export interface FormGroupInfoProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {}

export interface FormGroupControlsProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  layout?: FormGroupControlsLayout;
}

export enum FormGroupControlsLayout {
  Stacked = "stacked",
  Horizontal = "horizontal",
}
