import React from "react";
import classnames from "classnames";
import { OverflowMenuProps } from "./OverflowMenu.types";
import Icon from "../../Atoms/Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { FloatMenu } from "../../Atoms/Navigation/FloatMenu/FloatMenu";

export const OverflowMenu: React.FC<OverflowMenuProps> = React.forwardRef<
  HTMLDivElement,
  OverflowMenuProps
>(({ className, disabled, triggerLabel, ...props }, ref) => {
  return (
    <FloatMenu ref={ref} {...props}>
      <button
        aria-label={triggerLabel ?? "Menu"}
        className={classnames("overflow-menu__button", {}, "button")}
      >
        <Icon icon={IconKeys.MoreHorizontal} />
      </button>
    </FloatMenu>
  );
});

export default OverflowMenu;
