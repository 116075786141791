import React, { useEffect, useImperativeHandle, useRef } from "react";
import { CopyProps } from "./Copy.types";
import classnames from "classnames";
import { init } from "@gbg/gbgcomponentlibrary/src/components/atoms/copy/copy";
import Button from "../Button/Button";
import ButtonType from "@storybook/addon-knobs/dist/components/types/Button";
import { ButtonKind, ButtonSize } from "../Button/Button.types";

export const Copy: React.FC<CopyProps> = React.forwardRef<
  HTMLDivElement,
  CopyProps
>(
  (
    {
      content,
      buttonKind = ButtonKind.Secondary,
      buttonSize = ButtonSize.Small,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const cardClasses = classnames("copy", className);
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => innerRef.current);
    useEffect(() => {
      if (innerRef.current) {
        init(innerRef.current);
      }
    }, [innerRef.current]);

    return (
      <div
        data-text={content}
        className={cardClasses}
        {...props}
        ref={innerRef}
      >
        {children}
        <Button
          className="copy__button"
          kind={buttonKind}
          size={buttonSize}
        ></Button>
      </div>
    );
  }
);
