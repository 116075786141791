import { IExtendableElement } from "../../Base/Components";
import { HTMLAttributes } from "react";

export interface ProgressProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  orientation?: ProgressOrientation;
  steps: ProgressStep[];
}

export interface ProgressStep {
  title: string;
  state: ProgressStepState;
}

export enum ProgressStepState {
  Pending = "pending",
  Active = "active",
  Complete = "complete",
}

export enum ProgressOrientation {
  Veritical = "vertical",
  Horizontal = "horizontal",
}
