import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../Base/Components";

export interface PillProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: PillType;
  shape?: PillShape;
  className?: string;
  outlined?: boolean;
  removable?: boolean;
  onRemoveClicked?: (content?: string) => void;
}

export enum PillType {
  Info = "info",
  Success = "success",
  Warn = "warn",
  Error = "error",
  Light = "light",
  Dark = "dark",
}

export enum PillShape {
  Rounded = "rounded",
  Square = "square",
}
