import React, { useState } from "react";
import classnames from "classnames";
import { PasswordProps } from "./Password.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Password: React.FC<PasswordProps> = React.forwardRef<
  HTMLInputElement,
  PasswordProps
>(
  (
    { className, icon, error, disabled, placeholder, ...props }: PasswordProps,
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    const passwordToggle = () => {
      setVisible(!visible);
    };

    const checkboxClasses = classnames(
      "control",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    const passwordClasses = classnames("password__toggle", {
      "password__toggle--visible": visible,
    });
    return (
      <div className={checkboxClasses}>
        <input
          type={visible ? "text" : "password"}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
          ref={ref}
        ></input>
        <div onClick={passwordToggle} className={passwordClasses}>
          <Icon icon={IconKeys.Eye}></Icon>
          <Icon icon={IconKeys.EyeOff}></Icon>
        </div>
        {icon ? <Icon icon={icon} className={"text-g500"}></Icon> : null}
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Password;
