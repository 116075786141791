import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";

export interface GlobalNotificationProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: GlobalNotificationType;
}

export enum GlobalNotificationType {
  Info = "info",
  Success = "success",
  Warn = "warn",
  Error = "error",
  Neutral = "neutral",
}
