import { IExtendableElement } from "../../../Base/Components";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { ButtonHTMLAttributes, FieldsetHTMLAttributes } from "react";

export interface ToggleButtonGroupProps
  extends IExtendableElement<FieldsetHTMLAttributes<HTMLFieldSetElement>> {
  title: string;
  size?: ToggleButtonGroupSize;
  buttons: ToggleButtonGroupButton[];
  selectedIndex?: number;
  onButtonSelected?: (selectedIndex: number) => void;
}

export interface ToggleButtonGroupButton {
  title: string;
  icon?: IconKeys | string;
}

export enum ToggleButtonGroupSize {
  Small = "small",
  Regular = "regular",
}
