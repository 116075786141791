import React from 'react'
import classnames from 'classnames';
import { TableHeadProps } from './TableHead.types';



export const TableHead: React.FC<TableHeadProps> = React.forwardRef<HTMLTableSectionElement,TableHeadProps>(({ children, className, ...props}, ref) => {
    const tableHeadClasses = classnames(`table__head`, className);


    return <thead
            className={tableHeadClasses}
            {...props}
            ref={ref}
            >
        {children}
    </thead>;   
});

export default TableHead;