import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { HistogramProps } from "./Histogram.types";
import classnames from "classnames";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { histogram } from "@gbg/gbgcomponentlibrary/src/components/atoms/visualisation/charts/histogram/histogram";
import { ChartSets, ColorSets } from "@gbg/gbgcomponentlibrary/src/_colorSets";
import { arrInfIndex } from "@gbg/gbgcomponentlibrary/src/_utilities";

export const Histogram: React.FC<HistogramProps> = React.forwardRef<
  Chart | undefined,
  HistogramProps
>(({ config, rerender, className, ...props }, ref) => {
  const [chart, setChart] = useState<Chart>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useImperativeHandle<Chart | undefined, Chart | undefined>(ref, () => chart, [
    chart,
  ]);

  const destroyChart = () => {
    if (chart) chart.destroy();
  };

  const render = () => {
    setChart(histogram(canvasRef.current, config));
  };
  const update = () => {
    if (!chart) return;

    chart.options.plugins.legend.display = config.legend;

    const { datasets: newData, ...newChartData } = config.data;
    const { datasets: currentData } = chart.config.data;

    Object.assign(chart.config.data, newChartData);

    chart.config.data.datasets = newData.map((nds, i) => {
      const currentSet = currentData.find((d) => d.label == nds.label);
      if (!currentSet || !nds.data) return { ...nds };
      if (!currentSet.data) {
        currentSet.data = [];
      } else {
        currentSet.data.length = nds.data.length;
      }

      Object.assign(currentSet.data, nds.data);
      Object.assign(currentSet, { ...nds, data: currentSet.data });

      if (!Array.isArray(config.colors)) {
        const newColorSet = ColorSets.charts[config.colors as ChartSets];
        currentSet.borderColor = arrInfIndex(newColorSet, i);
        currentSet.backgroundColor = arrInfIndex(newColorSet, i);
      }

      return currentSet;
    });

    chart.update();
  };

  useEffect(() => {
    render();
    return () => destroyChart();
  }, []);

  useEffect(() => {
    if (rerender) {
      destroyChart();
      setTimeout(() => {
        render();
      }, 0);
    } else {
      update();
    }
  }, [config]);

  const histogramClasses = classnames("chart", "chart--histogram", className);
  return (
    <canvas ref={canvasRef} className={histogramClasses} {...props}></canvas>
  );
});

export default Histogram;
