import React from 'react'
import classnames from 'classnames';
import { TableBodyProps } from './TableBody.types';



export const TableBody: React.FC<TableBodyProps> = React.forwardRef<HTMLTableSectionElement,TableBodyProps>(({ children, className, ...props}, ref) => {
    const tableBodyClasses = classnames(`table__body`, className);


    return <tbody
            className={tableBodyClasses}
            {...props}
            ref={ref}
            >
        {children}
    </tbody>;   
});

export default TableBody;