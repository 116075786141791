import React from "react";
import classnames from "classnames";
import { TextareaProps } from "./Textarea.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, error, disabled, placeholder, ...props }: TextareaProps,
    ref
  ) => {
    const textareaClasses = classnames(
      "control",
      "control--textarea",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <div className={textareaClasses}>
        <textarea
          placeholder={placeholder}
          disabled={disabled}
          {...props}
          ref={ref}
        ></textarea>
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Textarea;
