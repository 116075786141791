import React, { KeyboardEvent, useCallback, useEffect } from "react";
import { PrimaryNavigationProps } from "./PrimaryNavigation.types";
import Icon from "../../../Atoms/Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import classnames from "classnames";
import { IDefinedLink } from "../../../Base/Components";

export const PrimaryNavigation: React.FC<PrimaryNavigationProps> = React.forwardRef<
  HTMLDivElement,
  PrimaryNavigationProps
>(({ onClose, open, children, footerLinks, className, ...props }, ref) => {
  const handleClose = () => {
    onClose ? onClose() : null;
  };

  const handleEscapePress = useCallback(
    (evt) => {
      if (evt.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleEscapePress);
    return () => {
      document.removeEventListener("keyup", handleEscapePress);
    };
  }, [handleEscapePress]);

  return (
    <>
      <div
        className={classnames("page__navigationbg", {
          "page__navigationbg--open": open,
        })}
      ></div>
      <div
        className={classnames("page__navigation page__navigation--slide-over", {
          "page__navigation--open": open,
        })}
        {...props}
        ref={ref}
      >
        <nav className={classnames("primary-nav", className)}>
          <div className="primary-nav__header">
            <Icon
              icon={IconKeys.X}
              className="primary-nav__closer"
              tabIndex={0}
              onClick={() => {
                handleClose();
              }}
              onKeyUp={(evt: KeyboardEvent) => {
                if (evt.key === "Enter") {
                  handleClose();
                }
              }}
            ></Icon>
          </div>
          <div className="primary-nav__body">{children}</div>
          {footerLinks && (
            <div className="primary-nav__footer">
              {footerLinks.map((fl) =>
                React.isValidElement(fl) ? (
                  React.cloneElement(fl, {
                    className: "primary-nav__footer__link",
                  })
                ) : (
                  <a
                    className="primary-nav__footer__link"
                    target={(fl as IDefinedLink).newTab ? "_blank" : null}
                    href={(fl as IDefinedLink).link}
                  >
                    {(fl as IDefinedLink).label}
                  </a>
                )
              )}
            </div>
          )}
        </nav>
      </div>
    </>
  );
});

export default PrimaryNavigation;
