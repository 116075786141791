import React, { useEffect, useRef } from "react";
import { QRProps } from "./QR.types";
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 150,
  height: 150,

  image:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='25' viewBox='0 0 72 25' fill='none'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M30.4572 21.3036H38.05C40.4393 21.3036 41.6357 19.3 41.6357 17.3679C41.6357 15.4357 40.4393 13.9036 38.05 13.9036H30.4572V21.3036ZM30.4572 10.3857H37.1C40.9679 10.3857 41.0357 3.55359 37.1714 3.55359H30.4572V10.3857ZM37.1714 0.178589C40.2643 0.178589 42.2322 1.93573 43.1464 4.22145C44.1322 6.64645 43.8143 9.89645 41.3536 11.6536C44.1643 12.85 45.1857 15.0143 45.1857 17.3357C45.1857 21.025 42.7607 24.7536 38.05 24.7536H26.9429V0.178589H37.1714Z' fill='%23000D1A'/%3E%3Cpath d='M71.2607 10.3929H71.1465H67.5857H59L58.0179 13.7714H67.7215C67.075 18.0107 63.4215 21.2643 59.0036 21.2643C54.125 21.2643 50.1714 17.3107 50.1714 12.4321C50.1714 7.55357 54.125 3.6 59.0036 3.6C61.9822 3.6 64.6107 5.07857 66.2107 7.33571H70.3429C68.3893 3.01429 64.0464 0 59 0C52.1357 0 46.5679 5.56429 46.5679 12.4321C46.5679 19.2964 52.1322 24.8643 59 24.8643C65.8643 24.8607 71.4286 19.2964 71.4286 12.4321C71.4286 11.7357 71.3679 11.0536 71.2607 10.3929Z' fill='%23000D1A'/%3E%3Cpath d='M24.6893 10.3929H24.575H21.0143H12.4321L11.45 13.7714H21.1536C20.5071 18.0107 16.8536 21.2643 12.4357 21.2643C7.55714 21.2643 3.60357 17.3107 3.60357 12.4321C3.60357 7.55357 7.55714 3.6 12.4357 3.6C15.4143 3.6 18.0429 5.07857 19.6429 7.33571H23.775C21.8214 3.01429 17.4786 0 12.4321 0C5.56429 0 0 5.56786 0 12.4321C0 19.2964 5.56429 24.8607 12.4321 24.8607C19.2964 24.8607 24.8607 19.2964 24.8607 12.4286C24.8607 11.7357 24.8 11.0536 24.6893 10.3929Z' fill='%23000D1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='71.4286' height='25' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E",
  dotsOptions: {
    color: "#000D1A",
    type: "dots",
  },
  backgroundOptions: {
    color: "#FFFFFF",
  },
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  cornersDotOptions: {
    type: "dot",
  },
  imageOptions: {
    margin: 1,
    imageSize: 0.4,
  },
});

export const QR: React.FC<QRProps> = ({
  data,
  includeLogo,
  size,
  backgroundColor,
  ...props
}: QRProps) => {
  const ref = useRef(null);
  backgroundColor = backgroundColor || "#FFFFFF";
  if (typeof includeLogo == "undefined") {
    includeLogo = true;
  }

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);
  useEffect(() => {
    qrCode.update({
      data: data,
      image: includeLogo
        ? "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='25' viewBox='0 0 72 25' fill='none'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M30.4572 21.3036H38.05C40.4393 21.3036 41.6357 19.3 41.6357 17.3679C41.6357 15.4357 40.4393 13.9036 38.05 13.9036H30.4572V21.3036ZM30.4572 10.3857H37.1C40.9679 10.3857 41.0357 3.55359 37.1714 3.55359H30.4572V10.3857ZM37.1714 0.178589C40.2643 0.178589 42.2322 1.93573 43.1464 4.22145C44.1322 6.64645 43.8143 9.89645 41.3536 11.6536C44.1643 12.85 45.1857 15.0143 45.1857 17.3357C45.1857 21.025 42.7607 24.7536 38.05 24.7536H26.9429V0.178589H37.1714Z' fill='%23000D1A'/%3E%3Cpath d='M71.2607 10.3929H71.1465H67.5857H59L58.0179 13.7714H67.7215C67.075 18.0107 63.4215 21.2643 59.0036 21.2643C54.125 21.2643 50.1714 17.3107 50.1714 12.4321C50.1714 7.55357 54.125 3.6 59.0036 3.6C61.9822 3.6 64.6107 5.07857 66.2107 7.33571H70.3429C68.3893 3.01429 64.0464 0 59 0C52.1357 0 46.5679 5.56429 46.5679 12.4321C46.5679 19.2964 52.1322 24.8643 59 24.8643C65.8643 24.8607 71.4286 19.2964 71.4286 12.4321C71.4286 11.7357 71.3679 11.0536 71.2607 10.3929Z' fill='%23000D1A'/%3E%3Cpath d='M24.6893 10.3929H24.575H21.0143H12.4321L11.45 13.7714H21.1536C20.5071 18.0107 16.8536 21.2643 12.4357 21.2643C7.55714 21.2643 3.60357 17.3107 3.60357 12.4321C3.60357 7.55357 7.55714 3.6 12.4357 3.6C15.4143 3.6 18.0429 5.07857 19.6429 7.33571H23.775C21.8214 3.01429 17.4786 0 12.4321 0C5.56429 0 0 5.56786 0 12.4321C0 19.2964 5.56429 24.8607 12.4321 24.8607C19.2964 24.8607 24.8607 19.2964 24.8607 12.4286C24.8607 11.7357 24.8 11.0536 24.6893 10.3929Z' fill='%23000D1A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='71.4286' height='25' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"
        : null,
      height: size,
      width: size,
      backgroundOptions: {
        color: backgroundColor,
      },
    });
  }, [data, includeLogo, size, backgroundColor]);

  return <div data-testid="qr-component" ref={ref} {...props}></div>;
};

export default QR;
