import React from "react";
import { DatetimeFormatterProps } from "./DateTime.types";

export const DateTimeFormatter = React.forwardRef<
  HTMLSpanElement,
  DatetimeFormatterProps
>(({ d, options, ...props }: DatetimeFormatterProps, ref) => {
  const formatter = new Intl.DateTimeFormat(undefined, options);
  return <span {...props}>{formatter.format(d)}</span>;
});

export default DateTimeFormatter;
