import React from "react";
import classnames from "classnames";
import { LinkProps } from "./Link.types";
import {
  Link as RouterLink,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import { useAnimatedNavigate } from "../../Hooks/useAnimatedNavigate";

export const Link: React.FC<LinkProps> = React.forwardRef<
  HTMLAnchorElement,
  LinkProps
>(
  (
    {
      animate = true,
      end,
      children,
      className: classNameProp = "",
      link,
      ...props
    },
    ref
  ) => {
    const location = useLocation();
    const navigate = useAnimatedNavigate();
    let locationPathname = location.pathname;
    let path = useResolvedPath(link, { relative: props.relative });
    let isActive =
      locationPathname === path.pathname ||
      (!end &&
        locationPathname.startsWith(path.pathname) &&
        locationPathname.charAt(path.pathname.length) === "/");

    let className: string | undefined;
    if (typeof classNameProp === "function") {
      className = classNameProp({ isActive });
    } else {
      className = classNameProp;
    }
    const linkClasses = classnames("link", className);

    return animate ? (
      <a
        className={linkClasses}
        onClick={() => {
          navigate(link);
        }}
        {...props}
      >
        {children}
      </a>
    ) : (
      <RouterLink className={linkClasses} to={link}>
        {children}
      </RouterLink>
    );
  }
);

export default Link;
