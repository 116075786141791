import React from 'react'
import classnames from 'classnames';
import { TableFootProps } from './TableFoot.types';



export const TableFoot: React.FC<TableFootProps> = React.forwardRef<HTMLTableSectionElement,TableFootProps>(({ children, className, ...props}, ref) => {
    const tableFootClasses = classnames(`table__footer`, className);


    return <tfoot
            className={tableFootClasses}
            {...props}
            ref={ref}
            >
        {children}
    </tfoot>;   
});

export default TableFoot;