import React from "react"
import { AlertBarProps, AlertBarTextProps, AlertBarType } from "./AlertBar.types";
import classnames from "classnames"
import Icon from "../../Atoms/Icon/Icon";

export const AlertBar: React.FC<AlertBarProps> = React.forwardRef<HTMLDivElement, AlertBarProps>(({ type, children, icon, className, ...props }, ref) => {
    const alertBarClasses = classnames("alert-bar", {
        "alert-bar--info": type == AlertBarType.Info,
        "alert-bar--success": type == AlertBarType.Success,
        "alert-bar--error": type == AlertBarType.Error,
        "alert-bar--warn": type == AlertBarType.Warn,
    }, className);
    return <div
        className={alertBarClasses}
        {...props}
        ref={ref}
    >
        {
            icon && 
            <Icon
                icon={icon}
                className="alert-bar__icon"
            ></Icon>
        }
        {children}
    </div>;
});

export const AlertBarText: React.FC<AlertBarTextProps> = React.forwardRef<HTMLDivElement, AlertBarTextProps>(({ className, title, text, ...props }, ref) => {
    const classes = classnames('alert-bar__text', className);
    return <div className={classes} {...props} ref={ref} >
        <span className={classnames('alert-bar__title', 'heading-xsmall')}>{title}</span>
        <p className={classnames('alert-bar__text', 'paragraph-small')}>{text}</p>
    </div>
});

export default AlertBar;