import React, { FunctionComponent } from "react";
import classnames from "classnames";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { LegendProps } from "./Legend.types";

export const Legend: FunctionComponent<LegendProps> = React.forwardRef<
  HTMLLegendElement,
  LegendProps
>(({ error, children, className, inline, ...props }, ref) => {
  const labelClasses = classnames(
    "label",
    {
      "label--inline": inline,
    },
    className
  );
  return (
    <legend className={labelClasses} {...props} ref={ref}>
      {children}
      {error ? (
        <Icon
          icon={IconKeys.AlertTriangle}
          className="text-o600 icon--smaller"
        />
      ) : null}
    </legend>
  );
});

export default Legend;
