import React from "react";
import { SecondaryNavigationProps } from "./SecondaryNavigation.types";
import classnames from "classnames";

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> = React.forwardRef<
  HTMLDivElement,
  SecondaryNavigationProps
>(({ onClose, open, children, className, ...props }, ref) => (
  <nav ref={ref} className={classnames("secondary-nav", className)} {...props}>
    <div className="secondary-nav__body">{children}</div>
  </nav>
));

export default SecondaryNavigation;
