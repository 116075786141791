import React, { useState } from "react";
import classnames from "classnames";
import { CreateAvatarDataUri } from "@gbg/gbgcomponentlibrary/src/components/atoms/avatar/avatar";
import { AvatarProps, AvatarSize } from "./Avatar.types";

export const Avatar = React.forwardRef<HTMLImageElement, AvatarProps>(
  ({ className, name, size, image, ...props }: AvatarProps, ref) => {
    const avatarClasses = classnames("avatar", className);
    const avSize =
      size === AvatarSize.Small ? 24 : size === AvatarSize.TopBar ? 28 : 32;
    const src = image ? image : CreateAvatarDataUri(name, avSize);
    return (
      <img
        {...props}
        data-testid="avatar-component"
        alt={name}
        title={name}
        className={avatarClasses}
        width={avSize}
        height={avSize}
        src={src}
        ref={ref}
      />
    );
  }
);

export default Avatar;
