import React from "react"
import { TabPanelProps } from "./TabPanel.types"
import classnames from "classnames";

export const TabPanel: React.FC<TabPanelProps> = React.forwardRef<HTMLDivElement, TabPanelProps>(({ className, children, index, selectedIndex, ...props }, ref) => {
    const panelClasses = classnames("tab-views__view", {
        "tab-views__view--active": selectedIndex === index
    }, className);
    return <div 
            className={panelClasses}
            {...props}
            ref={ref}
            >
                {children}
            </div>;
})

export default TabPanel;