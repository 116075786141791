import React, { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { ActiveListProps, ActiveListState } from "./ActiveList.types";
import Icon from "../../Icon/Icon";
import Slug from "../Slug/Slug";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import Button from "../../Button/Button";
import { ButtonKind } from "../../Button/Button.types";
import Checkbox from "../Checkbox/Checkbox";

export const ActiveList: React.FC<ActiveListProps> = React.forwardRef<
  HTMLDivElement,
  ActiveListProps
>(
  (
    {
      items,
      contentRenderer,
      className,
      searchActive,
      searchInactive,
      inactiveTitle,
      activeTitle,
      searchValue,
      onItemMoved,
      sort = () => 1,
      ...props
    },
    ref
  ) => {
    const activeListClasses = classnames("active-list", className);
    const [getItems, setItems] = useState<{ active: any[]; inactive: any[] }>(
      items
    );
    const [getActiveSelectedItems, setActiveSelectedItems] = useState<any[]>(
      []
    );
    const [getInactiveSelectedItems, setInactiveSelectedItems] = useState<
      any[]
    >([]);
    const [activeSearchValue, setActiveSearchValue] = useState<string>("");
    const [inactiveSearchValue, setInactiveSearchValue] = useState<string>("");

    if ((searchActive || searchInactive) && !searchValue)
      throw "In order to allow searching a 'searchValue' prop must be provided";

    return (
      <div {...props} className={activeListClasses}>
        <div className="active-list__list active-list__list--inactive">
          <div className="active-list__header">
            <h3 className="active-list__title">
              {inactiveTitle} (
              <span className="active-list__count">
                {getItems.inactive.length}
              </span>
              )
            </h3>
            {searchInactive && (
              <Slug
                className="active-list__search"
                slug={IconKeys.Search}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setInactiveSearchValue(evt.currentTarget.value);
                }}
              />
            )}
          </div>
          {getItems.inactive
            .filter((i) => {
              const sv = searchValue?.(i) ?? "";
              return sv
                .toLowerCase()
                .includes(inactiveSearchValue.toLowerCase());
            })
            .sort(sort)
            .map((i) => (
              <div className="active-list__item">
                <div className="active-list__item-control">
                  <Checkbox
                    checked={getInactiveSelectedItems.includes(i)}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      if (evt.currentTarget.checked) {
                        setInactiveSelectedItems([
                          ...getInactiveSelectedItems,
                          i,
                        ]);
                      } else {
                        setInactiveSelectedItems(
                          getInactiveSelectedItems.filter((s) => s != i)
                        );
                      }
                    }}
                  />
                </div>
                <div className="active-list__item-content">
                  {contentRenderer(i)}
                </div>
              </div>
            ))}
        </div>
        <div className="active-list__controls">
          <Button
            kind={ButtonKind.Secondary}
            className="active-list__controls--all-active"
            aria-label="Set all active"
            onClick={() => {
              const movingItems = [...getItems.inactive];
              setItems({
                active: [...getItems.active, ...getItems.inactive],
                inactive: [],
              });
              onItemMoved?.(movingItems, ActiveListState.Active, getItems);
              setActiveSelectedItems([]);
              setInactiveSelectedItems([]);
            }}
          >
            {">>"}
          </Button>
          <Button
            kind={ButtonKind.Secondary}
            className="active-list__controls--active"
            aria-label="Set selected active"
            onClick={() => {
              setItems({
                active: [...getInactiveSelectedItems, ...getItems.active],
                inactive: getItems.inactive.filter(
                  (a) => !getInactiveSelectedItems.includes(a)
                ),
              });
              onItemMoved?.(
                getInactiveSelectedItems,
                ActiveListState.Active,
                getItems
              );
              setActiveSelectedItems([]);
              setInactiveSelectedItems([]);
            }}
          >
            {">"}
          </Button>
          <Button
            kind={ButtonKind.Secondary}
            className="active-list__controls--inactive"
            aria-label="Set selected inactive"
            onClick={() => {
              setItems({
                inactive: [...getActiveSelectedItems, ...getItems.inactive],
                active: getItems.active.filter(
                  (a) => !getActiveSelectedItems.includes(a)
                ),
              });
              onItemMoved?.(
                getActiveSelectedItems,
                ActiveListState.Inactive,
                getItems
              );
              setActiveSelectedItems([]);
              setInactiveSelectedItems([]);
            }}
          >
            {"<"}
          </Button>
          <Button
            kind={ButtonKind.Secondary}
            className="active-list__controls--all-inactive"
            aria-label="Set all inactive"
            onClick={() => {
              const movingItems = [...getItems.active];
              setItems({
                inactive: [...getItems.active, ...getItems.inactive],
                active: [],
              });
              onItemMoved?.(movingItems, ActiveListState.Inactive, getItems);
              setActiveSelectedItems([]);
              setInactiveSelectedItems([]);
            }}
          >
            {"<<"}
          </Button>
        </div>
        <div className="active-list__list active-list__list--active">
          <div className="active-list__header">
            <h3 className="active-list__title">
              {activeTitle} (
              <span className="active-list__count">
                {getItems.active.length}
              </span>
              )
            </h3>
            {searchActive && (
              <Slug
                className="active-list__search"
                slug={IconKeys.Search}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setActiveSearchValue(evt.currentTarget.value);
                }}
              />
            )}
          </div>
          {getItems.active
            .filter((i) => {
              const sv = searchValue?.(i) ?? "";
              return sv.toLowerCase().includes(activeSearchValue.toLowerCase());
            })
            .sort(sort)
            .map((i) => (
              <div className="active-list__item">
                <div className="active-list__item-control">
                  <Checkbox
                    checked={getActiveSelectedItems.includes(i)}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      if (evt.currentTarget.checked) {
                        setActiveSelectedItems([...getActiveSelectedItems, i]);
                      } else {
                        setActiveSelectedItems(
                          getActiveSelectedItems.filter((s) => s != i)
                        );
                      }
                    }}
                  />
                </div>
                <div className="active-list__item-content">
                  {contentRenderer(i)}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
);

export default ActiveList;
