import React from "react";
import classnames from "classnames";
import { SecondaryNavigationItemProps } from "./Item.types";

export const Item: React.FC<SecondaryNavigationItemProps> = React.forwardRef<
  HTMLLIElement,
  SecondaryNavigationItemProps
>(({ active, children, link, className, ...props }, ref) => {
  const itemClasses = classnames(
    "secondary-nav__item",
    {
      "secondary-nav__item--active": active,
    },
    className
  );
  return (
    <li className={itemClasses} {...props} ref={ref}>
      <a className="secondary-nav__link" href={link}>
        <div className="secondary-nav__linktext">{children}</div>
      </a>
    </li>
  );
});

export default Item;
