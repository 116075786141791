import { GridableComponentProps } from "../Grid/Grid.types";
import { IExtendableElement } from "../../Base/Components";
import { HTMLAttributes } from "react";

export interface SkeletonProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
    variant?: SkeletonVariant;
}

export enum SkeletonVariant {
  Circle = "circle",
  Paragraph = "paragraph",
}
