import React from "react"
import { FormGroupProps, FormGroupType, FormGroupInfoProps, FormGroupControlsProps, FormGroupControlsLayout } from "./FormGroup.types"
import classnames from "classnames"

export const FormGroup: React.FC<FormGroupProps> = React.forwardRef<HTMLDivElement, FormGroupProps>(({ type, className, children, ...props }, ref) => {
    type = type ?? FormGroupType.Standard;
    const groupClasses = classnames("form-group", {
        "form-group--radio": type === FormGroupType.Checkbox || type === FormGroupType.Radio,
    }, className);
    return <div
            className={groupClasses}
            {...props}
            ref={ref}
        >
            {children}
        </div>
})

export const FormGroupInfo: React.FC<FormGroupInfoProps> = React.forwardRef<HTMLDivElement, FormGroupInfoProps>(({children, ...props}, ref) => {
    return <div className="form-group__info" {...props} ref={ref}>{children}</div>
})

export const FormGroupControls: React.FC<FormGroupControlsProps> = React.forwardRef<HTMLDivElement, FormGroupControlsProps>(({ layout, children, ...props}, ref) => {
    const controlsClasses = classnames("form-group__controls", {
        "form-group__controls--horizontal": layout === FormGroupControlsLayout.Horizontal
    })
    return <div className={controlsClasses} {...props} ref={ref}>{children}</div>
})

export default FormGroup;