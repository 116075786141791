import React from "react";
import classnames from "classnames";
import { TablePagerProps } from "./TablePager.types";
import { Button } from "../../Button/Button";
import { ButtonKind } from "../../Button/Button.types";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import Icon from "../../Icon/Icon";

export const TablePager = React.forwardRef<HTMLDivElement, TablePagerProps>(
  (
    {
      children,
      currentPage,
      totalPages,
      className,
      onNext,
      onPrevious,
      nextLabel,
      previousLabel,
      ...props
    }: TablePagerProps,
    ref
  ) => {
    const tablePagerClasses = classnames(`pager`, className);

    currentPage = currentPage ?? 1;

    return (
      <div className={tablePagerClasses} {...props} ref={ref}>
        <div className="pager__page">Page {currentPage}</div>

        <Button
          disabled={currentPage <= 1}
          kind={ButtonKind.Tertiary}
          className="button--pager"
          onClick={() => {
            onPrevious?.();
          }}
          aria-label={previousLabel ?? "Previous page"}
        >
          <Icon icon={IconKeys.ChevronLeft}></Icon>
        </Button>
        <Button
          disabled={currentPage == totalPages}
          kind={ButtonKind.Tertiary}
          className="button--pager"
          onClick={() => {
            onNext?.();
          }}
          aria-label={nextLabel ?? "Next page"}
        >
          <Icon icon={IconKeys.ChevronRight}></Icon>
        </Button>
      </div>
    );
  }
);

export default TablePager;
