import { IExtendableElement } from "../../Base/Components";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { HTMLAttributes } from "react";

export interface InlineAlertBarProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: InlineAlertBarType;
  className?: string;
  icon?: IconKeys | string;
  dismissable?: boolean;
  onDismissed?: () => void;
}

export enum InlineAlertBarType {
  Help = "help",
  Success = "success",
  Warn = "warn",
}
