import React, { useState } from "react";
import classnames from "classnames";
import { TableRowProps } from "./TableRow.types";
import TableCell from "../TableCell/TableCell";

export const TableRow: React.FC<TableRowProps> = React.forwardRef<
  HTMLTableRowElement,
  TableRowProps
>(
  (
    {
      children,
      isExpandable,
      isExpanded,
      isExpansion,
      isHeader,
      className,
      ...props
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState<boolean>(isExpanded);

    const tableRowClasses = classnames(
      `table__row`,
      {
        "table__row--header": isHeader,
        "table__row--expansion": isExpansion,
        "table__row--expanded": expanded,
        "table__row--expandable": isExpandable,
      },
      className
    );

    return (
      <tr className={tableRowClasses} {...props} ref={ref}>
        {isExpandable ? (
          <TableCell
            isExpander={true}
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        ) : isExpansion ? (
          <TableCell>&nbsp;</TableCell>
        ) : null}
        {children}
      </tr>
    );
  }
);

export default TableRow;
